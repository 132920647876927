import { CommonState } from "./interface";

// Initial Setting State
export const initialState: CommonState = {
  spinner: false,
  loader: { type: "", value: "", isLoading: false },
  snackbar: { open: false, message: "", variant: "success" },
  profileActiveTab: "overview",
  communityFilterQuery: {},
  resourceFilterQuery: {}
};
