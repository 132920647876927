import { httpRequest } from "../../app/Auth";
import {
  ADD_ICO,
  ADD_REFERRAL_LEVELS,
  GET_ALL_COMMUNITIES,
  GET_COMMUNITY_BY_ID,
  GET_FRIENDS,
  GET_ICO_DETAILS,
  GET_INVESTOR_PURCHASE_DETAILS,
  GET_REFERRAL_LIST,
  GET_Rewards_REFERRAL_POINTS,
  GET_USERS_LIST,
  REPORTED_TOPIC_LIST
} from "../../Constants";
import { setLoader, setSnackbar, setSpinner } from "../common/actions";
import { store } from "../index";
import * as CommunityType from "./types";
export const getCommunity = (community_title_slug) => async (dispatch) => {
  dispatch(setSpinner(true));
  dispatch({
    type: CommunityType.GET_COMMUNITY,
    payload: null
  });
  httpRequest({
    type: "get",
    endpoint: GET_COMMUNITY_BY_ID + "/" + community_title_slug,
    formData: {},
    callBack: (data, msg) => {
      dispatch(setSpinner(false));
      if (data && data.status) {
        dispatch({
          type: CommunityType.GET_COMMUNITY,
          payload: data
        });
      } else {
        dispatch({
          type: CommunityType.SET_UNAUTHORIZED,
          payload: {}
        });
      }
    }
  });
};

export const updateCommunity = (payload) => (dispatch) => {
  dispatch({
    type: CommunityType.GET_COMMUNITY,
    payload: { data: payload }
  });
};

export const getUsers = () => async (dispatch) => {
  dispatch(setSpinner(true));
  httpRequest({
    type: "post",
    endpoint: GET_USERS_LIST,
    formData: {
      limit: 2
    },
    callBack: (data, msg) => {
      dispatch(setSpinner(false));
      if (data && data.status) {
        dispatch({
          type: CommunityType.GET_USERS,
          payload: data.recommendationResult
        });
      }
    }
  });
};
export const getCommunities = () => async (dispatch) => {
  try {
    let { communities } = store.getState().communityReducer;
    if (communities) {
      return;
    }

    dispatch(setSpinner(true));
    dispatch({
      type: CommunityType.GET_COMMUNITIES,
      payload: null
    });
    httpRequest({
      instance: "instance",
      type: "post",
      endpoint: GET_ALL_COMMUNITIES,
      formData: {},
      callBack: (data, msg) => {
        if (data && data.status) {
          dispatch({
            type: CommunityType.GET_COMMUNITIES,
            payload: data.data
          });
        }
        dispatch(setSpinner(false));
      }
    });
  } catch (err) {
    dispatch(setSpinner(false));
  } finally {
    dispatch(setSpinner(false));
  }
};

export const updateCommunities = (payload) => async (dispatch) => {
  try {
    console.log("payload", payload);
    dispatch({
      type: CommunityType.GET_COMMUNITIES,
      payload: payload
    });
  } catch (err) {
    dispatch(setSpinner(false));
  }
};

export const setModalCreateTopic = () => async (dispatch) => {
  try {
    dispatch({
      type: CommunityType.SET_MODAL_CREATE_TOPIC,
      payload: ""
    });
  } catch (err) {}
};

export const getRepotedTopics = (communityId) => (dispatch) => {
  try {
    httpRequest({
      type: "get",
      endpoint: `${REPORTED_TOPIC_LIST}/${communityId}`,
      formData: {},
      callBack: (data, msg) => {
        dispatch(setSpinner(false));

        if (data && data.status) {
          dispatch({
            type: CommunityType.GET_TOPICS,
            payload: {
              topics: {
                topicDetails: data.data,
                totalTopics: data.totalReports,
                totalReports: data.totalReports
              },
              formFilterData: {
                offset: 0,
                limit: 4,
                sorting: "recent",
                type: ""
              }
            }
          });
        }
      },
      isPublic: false
    });
  } catch (err) {}
};

export const getTopicsRed =
  (formData, isPagination = false, type = "") =>
  async (dispatch, getState) => {
    if (type === "cleanupDiscussion") {
      dispatch({
        type: CommunityType.GET_TOPICS,
        payload: {
          topics: null,
          formFilterData: { offset: 0, limit: 4, sorting: "recent", type: "" }
        }
      });
      return;
    }

    let { community, topics, formFilterData } = getState().communityReducer;
    dispatch(setLoader({ type: "getTopic", value: "", isLoading: true }));

    formData = { ...formFilterData, ...formData };
    if (type === "mySubscribe") {
      delete formData.userId;
    } else if (type === "myPost") {
      delete formData.subscriber;
    } else if (type === "postWithTags") {
      if (formData.tagsIds === "") {
        delete formData.tagsIds;
      }
    } else if (type === "postWithCategories") {
      if (formData.categoryIds === "") {
        delete formData.categoryIds;
      }
    } else {
      if ((type === "" && formFilterData.type === "") || type === "allPost") {
        delete formData.subscriber;
        delete formData.userId;
        if (formData?.categoryIds === "") {
          delete formData.categoryIds;
        }
      }
    }
    delete formData.type;
    httpRequest({
      type: "post",
      endpoint: "getTopics",
      formData: {
        ...formData,
        communityId: community?.communityId
      },
      callBack: (data, msg) => {
        dispatch(setSpinner(false));
        dispatch(setLoader({ type: "", value: "", isLoading: false }));
        if (!data || data?.status === false) {
          if (!isPagination) {
            dispatch({
              type: CommunityType.GET_TOPICS,
              payload: { topics: null, formFilterData: { ...formData, type } }
            });
          }
        } else {
          if (data) {
            if (isPagination && topics?.topicDetails?.length > 0 && formFilterData.type === type) {
              let tempDetails = [...topics.topicDetails];
              tempDetails = tempDetails?.concat(data?.topicDetails);
              let tempTopic = { ...topics, topicDetails: tempDetails };
              let tempFormData = { ...formData, type };
              dispatch({
                type: CommunityType.GET_TOPICS,
                payload: {
                  topics: tempTopic,
                  formFilterData: tempFormData
                }
              });
            } else {
              let tempFormData = { ...formData, type };

              dispatch({
                type: CommunityType.GET_TOPICS,
                payload: { topics: data, formFilterData: tempFormData }
              });
            }
          }
        }
      },
      isPublic: false
    });
    // dispatch(setLoader(false));
  };

export const handleTags = (allTags, id) => (dispatch) => {
  let { selectedTagList } = store.getState().communityReducer;
  let tempTags = [];
  if (selectedTagList.includes(id)) {
    tempTags = selectedTagList.filter((tag) => tag != id);
  } else {
    tempTags = [...selectedTagList, allTags.filter((tag) => tag._id === id)[0]._id];
  }
  dispatch({
    type: CommunityType.SET_TAG_LIST,
    payload: tempTags
  });
  let formData = {};
  let tagsIds = tempTags.join(",");
  formData = { tagsIds: tagsIds, offset: 0, limit: 4 };
  dispatch(getTopicsRed(formData, false, "postWithTags"));
};

export const handleCategory = (selectedCategories) => (dispatch) => {
  let formData = {};
  let categoryIds = selectedCategories.join(",");
  formData = { categoryIds: categoryIds, offset: 0, limit: 4 };
  dispatch(getTopicsRed(formData, false, "postWithCategories"));
};

export const setReferralRewardsPoints =
  (communityId, data, setActivitySaveDisable, rewardsPoints) => async (dispatch) => {
    try {
      dispatch(setSpinner(true));
      let endPoint = `${ADD_REFERRAL_LEVELS}/${communityId}`;
      const formData = {
        levels: data
      };
      httpRequest({
        type: "put",
        endpoint: endPoint,
        formData: formData,
        callBack: (data, msg) => {
          if (data && data.status) {
            setActivitySaveDisable(true);
            const newData = {
              data: {
                ...rewardsPoints?.data,
                levels: data?.data?.levels
              }
            };
            dispatch({
              type: CommunityType.GET_REWARD_POINTS,
              payload: newData
            });
            dispatch(
              setSnackbar({
                open: true,
                variant: "success",
                message: msg
              })
            );
          }
          if (!data.status) {
            setActivitySaveDisable(true);
            dispatch(
              setSnackbar({
                open: true,
                variant: "warning",
                message: msg
              })
            );
          }
        }
      });
    } catch (err) {}
  };

export const getIcoDetails = (communityId) => async (dispatch) => {
  try {
    // const endpoint = `${GET_ICO_DETAILS}/64feaeb9b0ecce2ceb7360de/6544d6940f10c95ab27201ca`;
    const endpoint = `${GET_ICO_DETAILS}/${communityId}`;
    httpRequest({
      type: "get",
      endpoint: endpoint,
      formData: {},
      callBack: (data, msg) => {
        if (data && data.status) {
          dispatch({
            type: CommunityType.GET_ICO_DETAILS,
            payload: data?.result
          });
        }
      }
    });
  } catch (error) {}
};

export const setIco = (communityId, formData) => async (dispatch) => {
  try {
    let endPoint = `${ADD_ICO}/${communityId}`;
    httpRequest({
      type: "post",
      endpoint: endPoint,
      formData: formData,
      callBack: (data, msg) => {
        if (data && data.status) {
          dispatch(
            setSnackbar({
              open: true,
              variant: "success",
              message: msg
            })
          );
        }
        if (!data.status) {
          dispatch(
            setSnackbar({
              open: true,
              variant: "warning",
              message: msg
            })
          );
        }
      }
    });
  } catch (err) {}
};

// export const saveLockupDetails = (communityId, icoId, formData) => async (dispatch) => {
//   try {
//     let endPoint = `${ADD_ICO_LOCKUP_INFO}/${communityId}/${icoId}`;
//     httpRequest({
//       type: "put",
//       endpoint: endPoint,
//       formData: formData,
//       callBack: (data, msg) => {
//         if (data && data.status) {
//           dispatch(
//             setSnackbar({
//               open: true,
//               variant: "success",
//               message: data.message
//             })
//           );
//         }
//         if (!data.status) {
//           dispatch(
//             setSnackbar({
//               open: true,
//               variant: "warning",
//               message: data.message
//             })
//           );
//         }
//       }
//     });
//   } catch (err) {}
// };

// export const updateIcoDetails = (communityId, icoId, formData) => async (dispatch) => {
//   const endpoint = `${UPDATE_ICO_DETAILS}/${communityId}/${icoId}`;

//   httpRequest({
//     type: "post",
//     endpoint: endpoint,
//     formData: formData,
//     callBack: (data, msg) => {
//       if (data && data.status) {
//         dispatch(
//           setSnackbar({
//             open: true,
//             variant: "success",
//             message: data.message
//           })
//         );
//       }
//       if (!data.status) {
//         dispatch(
//           setSnackbar({
//             open: true,
//             variant: "warning",
//             message: data.message
//           })
//         );
//       }
//     }
//   });
// };

export const getInvestorsPurchaseDetails = (userAddress) => async (dispatch) => {
  try {
    // const endpoint = `${GET_ICO_DETAILS}/64feaeb9b0ecce2ceb7360de/6544d6940f10c95ab27201ca`;
    const endpoint = `${GET_INVESTOR_PURCHASE_DETAILS}/${userAddress}`;
    httpRequest({
      type: "get",
      endpoint: endpoint,
      formData: {},
      callBack: (data, msg) => {
        if (data && data.status) {
          dispatch({
            type: CommunityType.GET_INVESTOR_PURCHASE,
            payload: data?.data
          });
        }
      }
    });
  } catch (error) {}
};

// export const updateInvestorPurchaseDetails = (userAddress, formData) => async (dispatch) => {
//   const endpoint = `${UPDATE_INVESTOR_PURCHASE_DETAILS}/${userAddress}`;

//   httpRequest({
//     type: "post",
//     endpoint: endpoint,
//     formData: formData,
//     callBack: (data, msg) => {
//       if (data && data.status) {
//         dispatch(
//           setSnackbar({
//             open: true,
//             variant: "success",
//             message: data.message
//           })
//         );
//       }
//       if (!data.status) {
//         dispatch(
//           setSnackbar({
//             open: true,
//             variant: "warning",
//             message: data.message
//           })
//         );
//       }
//     }
//   });
// };

export const getPopularTags = (keyword, _id) => async (dispatch) => {
  let endPoint = `getPopularTagsByCommunityId/${_id}`;
  dispatch({
    type: CommunityType.GET_POPULAR_TAGS,
    payload: []
  });
  httpRequest({
    type: "post",
    endpoint: endPoint,
    formData: { keyword: keyword || undefined },
    callBack: (data, msg) => {
      if (data) {
        dispatch({
          type: CommunityType.GET_POPULAR_TAGS,
          payload: data
        });
      }
    }
  });
};
export const getRewardsHistory =
  (formData, isPagination = false) =>
  async (dispatch) => {
    let { rewards } = store.getState().communityReducer;
    dispatch(setSpinner(true));
    httpRequest({
      type: "post",
      endpoint: "getRewardsHistory",
      formData: formData,
      callBack: (data, msg) => {
        dispatch(setSpinner(false));
        if (data) {
          if (isPagination) {
            let tempRewards = [...rewards.rewardList, ...data.rewardList];
            dispatch({
              type: CommunityType.GET_REWARD,
              payload: { ...rewards, rewardList: tempRewards }
            });
          } else {
            dispatch({
              type: CommunityType.GET_REWARD,
              payload: data
            });
          }
        }
      },
      isPublic: false
    });
  };
export const getTrendingTopics = (communityId, keywords) => async (dispatch) => {
  httpRequest({
    type: "get",
    endpoint: `getTrendingTopics/${communityId}/${keywords}`,
    callBack: (data, msg) => {
      if (data) {
        dispatch({
          type: CommunityType.GET_TRENDING_TOPICS,
          payload: data
        });
      }
    }
  });
};

export const getRewardReferralPoints = (communityId) => async (dispatch) => {
  store.dispatch(setSpinner(true));
  httpRequest({
    type: "get",
    endpoint: `${GET_Rewards_REFERRAL_POINTS}/${communityId}`,
    callBack: (data, msg) => {
      store.dispatch(setSpinner(false));
      if (data.status === true) {
        dispatch({
          type: CommunityType.GET_REWARD_POINTS,
          payload: data
        });
      }
    },
    isPublic: false
  });
};

export const getReferralList =
  (communityId, offset, isPagination = false) =>
  async (dispatch) => {
    store.dispatch(setSpinner(true));
    let { referralList } = store.getState().communityReducer;
    httpRequest({
      instance: "instanceTwo",
      type: "post",
      endpoint: `${GET_REFERRAL_LIST}/${communityId}`,
      formData: {
        offset: offset,
        limit: 10
      },
      callBack: (data, msg) => {
        store.dispatch(setSpinner(false));
        if (isPagination) {
          let tempData = [...referralList.userInfo, ...data.userInfo];
          dispatch({
            type: CommunityType.GET_REFERRAL_LIST,
            payload: { ...referralList, userInfo: tempData }
          });
        } else {
          dispatch({
            type: CommunityType.GET_REFERRAL_LIST,
            payload: data
          });
        }
      },
      isPublic: false
    });
  };

export const getAndSetUser = (usersDetailsAll) => async (dispatch) => {
  dispatch({
    type: CommunityType.SET_USERS_LIST,
    payload: usersDetailsAll
  });
};

export const setCommunityCategories =
  (
    community,
    type = "",
    setTotalCategory,
    setCategories,
    setTempCategories,
    categoryFilter,
    categoryType
  ) =>
  async (dispatch) => {
    let payload = {};
    let endPoint = "";
    if (categoryType === "topicCategories" || type === "calling_create_topic") {
      if (community) {
        endPoint = "getAllForumCategories";
        payload = {
          sorting: "topic_desc",
          offset: -1,
          limit: 10,
          communityId: community?.communityId
        };
      }
    } else if (categoryType === "resourceCategories") {
      endPoint = "getAllCourseCategories";
      payload = {
        offset: -1,
        limit: 100,
        communityId: community?.communityId
      };
    } else {
      endPoint = "getAllCommunityCategories";
      payload = {
        offset: 0,
        limit: 20,
        isAdmin: 1,
        keyword: categoryFilter
      };
    }

    httpRequest({
      instance: "instance",
      type: "post",
      endpoint: endPoint,
      formData: payload,
      callBack: (data, msg) => {
        if (data) {
          if (type === "calling_filter") {
            setTotalCategory(data.totalCategories);
            setCategories(data.categoryList);
            setTempCategories(data.categoryList);
          }

          dispatch({
            type: CommunityType.SET_CATEGORIES_LIST,
            payload: data
          });
        }
      }
    });
  };

export const resetCommunityStates = () => (dispatch) => {
  dispatch({
    type: CommunityType.RESET_ALL_STATES
  });
};

export const getAllFiends = () => (dispatch) => {
  try {
    httpRequest({
      instance: "instance",
      type: "post",
      endpoint: GET_FRIENDS,
      formData: {
        type: "accept",
        offset: 0,
        limit: 10
      },
      callBack: (data, msg) => {
        if (data && data.totalFriends > 0) {
          let friendsList = data.friendList.map((item) => {
            return {
              ...item,
              ...item.userInfo[0]
            };
          });
          dispatch({
            type: CommunityType.SET_MY_FRIENDS,
            payload: { friendList: friendsList, totalFriends: data.totalFriends }
          });
        }
      }
    });
  } catch (err) {}
};

export const setResource =
  (payload, type = "") =>
  (dispatch) => {
    if (type === "all") {
      dispatch({
        type: CommunityType.SET_ALL_RESOURCE,
        payload
      });
      return;
    }
    dispatch({
      type: CommunityType.SET_RESOURCE,
      payload
    });
  };

export const setRecomendedResource = (payload) => (dispatch) => {
  dispatch({
    type: CommunityType.RECOMMENDED_RESOURCE,
    payload
  });
};

export const updateThemeColor = (newThemeColor) => ({
  type: CommunityType.UPDATE_THEME_COLOR,
  payload: newThemeColor
});

export const getStripeAccounts = (getAccountCallBack) => (dispatch) => {
  httpRequest({
    instance: "instance",
    type: "post",
    endpoint: "payment/getMyAccounts",
    formData: {},
    callBack: (data, msg) => {
      if (data && data.status) {
        let temp = data.result;

        temp = temp.map((item) => {
          return { ...item, label: item.stripeId, value: item.stripeId, isActive: item.isActive };
        });
        getAccountCallBack(temp);
        dispatch({
          type: CommunityType.SET_STRIPE_ACCOUNTS,
          payload: temp
        });
      }
    }
  });
};

export const getStrpeRefreshAccount = (refreshCallBack) => (dispatch) => {
  dispatch(setLoader({ isLoading: true, type: "refreshAccount", value: "" }));
  httpRequest({
    instance: "instance",
    type: "post",
    endpoint: "payment/refreshAccount",
    formData: {},
    callBack: (data, msg) => {
      dispatch(setLoader({ isLoading: false, type: "", value: "" }));

      if (data && data.status) {
        let temp = data.result;

        temp = temp.map((item) => {
          return { ...item, label: item.stripeId, value: item.stripeId, isActive: item.isActive };
        });
        refreshCallBack(temp);
        dispatch({
          type: CommunityType.SET_STRIPE_ACCOUNTS,
          payload: temp
        });
      }
    }
  });
};

export const setSelectedRewardMenu = (data) => (dispatch) => {
  dispatch({
    type: CommunityType.SET_SELECTED_REWARD_MENU,
    payload: data
  });
};
