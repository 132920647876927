import axios from "axios";
import { BASE_URL_ONE, BASE_URL_TWO, EMAILID } from "../Constants";
import { logoutUser } from "../store/Auth/actions";
import { store } from "../store/index";
import { codes } from "./Messages";
const Auth = {
  instance: axios.create({
    baseURL: `${BASE_URL_ONE}api/`,
    headers: {
      "X-Frame-Options": "deny"
    }
  }),
  instanceTwo: axios.create({
    baseURL: `${BASE_URL_TWO}api/`
  }),

  isGoogleAuth: false,

  isKyc: false,

  isAuthenticated: false,

  email: "",

  phoneNumber: 0,

  referralCode: "",

  accessToken: "",

  refreshToken: "",

  themeColor: "dark",

  fullName: "",
  address: "",

  authenticate(authData) {
    Auth.isAuthenticated = true;
    Auth.email = authData["email"];
    Auth.accessToken = authData["accessToken"];
    Auth.refreshToken = authData["refreshToken"];
    Auth.instance.defaults.headers.common["Authorization"] = authData["accessToken"];
    Auth.instance.CancelToken = axios.CancelToken;
    Auth.instanceTwo.defaults.headers.common["Authorization"] = authData["accessToken"];
    Auth.instanceTwo.CancelToken = axios.CancelToken;
    Auth.isGoogleAuth = authData["tfa"] === 1 ? true : false;
    Auth.isIpWhiteList = authData["isIpWhiteList"] === 1 ? true : false;
    Auth.phoneNumber = authData["phoneNumber"];
    Auth.referralCode = authData["referralCode"];
    Auth.isKyc = authData["isKyc"] === 1 ? true : false;
    Auth.fullName = authData["fullName"];
    Auth.address = authData["address"];
  },

  async httpRequest({
    type,
    endpoint,
    formData,
    isPublic = true,
    token,
    callBack,
    time = 1,
    instance = "instance",
    config = {}
  }) {
    let response = "";
    switch (type) {
      case "get":
        response = await Auth[instance].get(endpoint, formData).catch((err) => {
          return err.response;
        });
        break;

      case "post":
        response = await Auth[instance].post(endpoint, formData, config).catch((err) => {
          return err.response;
        });
        break;

      case "put":
        response = await Auth[instance].put(endpoint, formData).catch((err) => {
          return err.response;
        });
        break;
      case "delete":
        response = await Auth[instance].delete(endpoint).catch((err) => {
          return err.response;
        });
        break;

      default:
        response = "";
    }
    if (isPublic) {
    } else {
      if (token) {
        // setting 2fa token
        Auth[instance].defaults.headers.common["Authorization"] = token;
        callBack(true);
        return;
      }
    }
    if (!response) {
      // network issue
      if (time <= 512) {
        callBack(false, codes[600]);
        let timer = setTimeout(() => {
          httpRequest({
            type: type,
            endpoint: endpoint,
            formData: formData,
            token: token,
            callBack: callBack,
            time: time * 2,
            instance
          });
          clearTimeout(timer);
        }, time * 1000);
      } else {
        callBack(false, Auth.codes[403]);
      }
      return;
    } else {
      let msg = "";

      if (response.status === 200) {
        // msg = response.data?.message || "";
        msg = codes[response.data?.code];
        callBack(response.data, msg);
        return;
      } else if (response.status === 500) {
        callBack(false, response.data.message || "Something went wrong");
        return;
      } else if (response.status === 400) {
        msg = codes[response.data?.code] || response.data.message;
        callBack({ ...response.data, status: false }, msg);
        return;
      } else if (response.status === 422) {
        msg = codes[response.data?.code] || response.data.messag;
        callBack(response.data, msg);
        return;
      } else if (response.status === 401) {
        msg = response.data?.message || "";
        callBack(false, msg);
        return;
      } else {
        msg = response.data?.message || "";
        callBack(false, msg);
        if (response.status !== 403) {
          callBack(false, msg);
          return;
        }
      }
      if (response.status === 403) {
        store.dispatch(logoutUser());
        // bad token
        // response = await Auth.updateAccessToken(); // false for bad refresh token, true for receiving access token
        // if (response) {
        //   httpRequest({
        //     type: type,
        //     endpoint: endpoint,
        //     formData: formData,
        //     token: token,
        //     callBack: callBack,
        //     instance
        //   });
        //   return;
        // } else {
        //   callBack(false, Auth.codes[403]);
        //   return;
        // }
      }
    }
  },

  async updateAccessToken() {
    Auth.instance.defaults.headers.common["Authorization"] = Auth.refreshToken;
    Auth.instanceTwo.defaults.headers.common["Authorization"] = Auth.refreshToken;
    let response;
    try {
      response = await Auth.instanceTwo.post("getNewToken");
    } catch (err) {
      response = err.response;
    }
    if (response && response.data && response.data.accessToken) {
      Auth.instance.defaults.headers.common["Authorization"] = response.data.accessToken;
      Auth.instance.CancelToken = axios.CancelToken;
      Auth.instanceTwo.defaults.headers.common["Authorization"] = response.data.accessToken;
      Auth.instanceTwo.CancelToken = axios.CancelToken;
      Auth.refreshToken = response.data.refreshToken;
      return true;
    } else if (response.data.code === 403) {
      localStorage.clear();
      window.location.href = EMAILID;
    }
    return false;
  },

  setThemeColor(val) {
    Auth.themeColor = val;
    sessionStorage.setItem("themeColor", val);
  },

  getAuthData() {
    let isAuthenticated = Auth.isAuthenticated;
    let isGoogleAuth = Auth.isGoogleAuth;
    let isKyc = Auth.isKyc;
    let isIpWhiteList = Auth.isIpWhiteList;
    let referralCode = Auth.referralCode;
    let email = Auth.email;
    let phoneNumber = Auth.phoneNumber;
    let themeColor = Auth.themeColor;
    let fullName = Auth.fullName;
    let address = Auth.address;

    return {
      isAuthenticated,
      isGoogleAuth,
      isKyc,
      isIpWhiteList,
      referralCode,
      email,
      phoneNumber,
      themeColor,
      fullName,
      address
    };
  }
};

const io = require("socket.io-client");
// "http://localhost:3002", -> Local
// "http://3.98.211.88:3004", -> Demo
//"https://cryptoati.io", -> Live
export const socket = io.connect("http://3.98.211.88:6002", {
  path: "/community/socket.io",
  secure: true,
  transports: ["websocket"]
});

socket.on("connect_error", (err) => {
  console.log(`connect_error due to ${err.message}`);
});

socket.on("connect", function () {
  console.log("Connected to WS server");
});

export default Auth;
export const httpRequest = Auth.httpRequest;
export const isAuth = Auth.isAuth;
export const authenticate = Auth.authenticate;
export const getAuthData = Auth.getAuthData;
export const setThemeColor = Auth.setThemeColor;
export const themeColor = Auth.themeColor;
