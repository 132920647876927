import React, { useState, useEffect } from "react";
import _clone from "lodash/clone";
import _escapeRegExp from "lodash/escapeRegExp";
import { S3_URL, s3url, documentTypes } from "./Constants";
import momentTz from "moment-timezone";

export const checkAdmin = () => {
  let isAdmin = false;
  const userData = localStorage.getItem("userData") || "";
  if (userData) {
    const parsed = JSON.parse(userData);
    isAdmin = parsed.usergroup == 1 || parsed.usergroup == 2 || false;
  }
  return isAdmin;
};
export function displayTextInput(text = "", isQuote = false) {
  let displayText = _clone(text);
  const tags = text.match(/@\[(.*?)\]\((.*?)\)/gi) || [];
  tags.map((myTag) => {
    const value = myTag.match(/\[(.*?)\]/gi) || [];
    const tagData = value[0] && value[0].replace("[", "").replace("]", "");
    const tagDisplayValue = tagData;
    displayText = displayText.replace(new RegExp(_escapeRegExp(myTag), "gi"), tagDisplayValue);
  });
  return displayText;
}
export const getUserName = (allUsers = [], id) => {
  const data = allUsers?.filter((option) => option.id === id)[0]
    ? allUsers?.filter((option) => option.id === id)[0].display
      ? allUsers?.filter((option) => option.id === id)[0].display
      : ""
    : "";
  return data;
};

export const isValidUrl = (string = "") => {
  let res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
};

export const createActivityStatement = (data) => {
  let item = data.item;
  let action = data.action;
  let ownerId = data.ownerId ? data.ownerId : 0;
  let owner = data.ownerInfo ? data.ownerInfo[0] : {};
  let sender = data.senderInfo ? data.senderInfo[0] : {};
  let user = data.userInfo ? data.userInfo[0] : {};
  let content = owner ? owner.fullname : "You ";
  if (
    item === "course_category" &&
    (action === "add" || action === "edit" || action === "delete")
  ) {
    return (
      <>
        <h6>{`${data.categoryName}`}</h6>
        <p className="m-0">{`${content} ${action}`}ed this Resource Category.</p>
      </>
    );
  } else if (
    item === "email_template" &&
    (action === "add" || action === "edit" || action === "delete")
  ) {
    return (
      <>
        <p className="m-0">{`${content} ${action}`}ed email template.</p>
      </>
    );
  } else if (
    item === "course_content" &&
    (action === "add" ||
      action === "edit" ||
      action === "delete" ||
      action === "start" ||
      action === "complete")
  ) {
    return (
      <>
        <h6>
          <a href={`/content/${data?.slug}`}>{`${data?.title}`}</a>
        </h6>{" "}
        <p className="m-0">{`${content} ${action}`}ed this Resource Module.</p>
      </>
    );
  } else if (
    item === "course" &&
    (action === "add" || action === "edit" || action === "delete" || action === "complete")
  ) {
    return (
      <>
        <h6>
          <a href={`/course/${data?.categorySlug}/${data?.slug}`}>{`${data?.title}`}</a>
        </h6>{" "}
        <p className="m-0">{`${content} ${action}`}ed this Resource.</p>
      </>
    );
  } else if (
    item === "community_category" &&
    (action === "add" || action === "edit" || action === "delete")
  ) {
    return (
      <>
        <h6>{`${data?.categoryName}`}</h6>
        <p className="m-0">{`${content} ${action}`}ed this Project Category.</p>
      </>
    );
  } else if (
    item === "forum_category" &&
    (action === "add" ||
      action === "edit" ||
      action === "delete" ||
      action === "subscribe" ||
      action === "unsubscribe")
  ) {
    return (
      <>
        <h6>{`${data?.categoryName}`}</h6> <p>{`${content} ${action}`}ed this Forum Category.</p>
      </>
    );
  } else if (item === "reply") {
    if (
      action === "add" ||
      action === "edit" ||
      // action === "delete" ||
      action === "up" ||
      action === "down"
    ) {
      if (action === "up") {
        action = "liked";
      } else if (action === "down") {
        action = "unlike";
      }
      // else if (action === "delete") {
      //   return (
      //     <>
      //       <h6>
      //         {`${content}`} deleted one reply content from{" "}
      //         <a
      //           href={`/community/${data.categorySlug}/${data.topic.slug}`}
      //         >{`${data.topic.title}`}</a>
      //         .
      //       </h6>{" "}
      //       {/* <p>{`${content}`} deleted one reply content from this topic.</p> */}
      //     </>
      //   );
      // }
      else {
        action = action + "ed";
        return (
          <>
            {" "}
            <h6>
              {`${content}`} {action !== "edited" ? <>commented</> : <>edited comment</>} on{" "}
              <a href={`/topic-detail/${data?.communityInfo?.slug}/${data?.topic?.slug}`}>
                {`${data?.topic?.title}`}
              </a>
            </h6>{" "}
            <p className="m-0">
              {/* {`${content} ${action}`} this reply " {`${data.reply.content}`}" Content. */}
              {`${data?.reply?.content}`}
            </p>
          </>
        );
      }
      return (
        <>
          <h6>
            {`${content} ${action}`} this reply " {`${data?.reply?.content}`}" on{" "}
            <a href={`/topic-detail/${data?.communityInfo?.slug}/${data?.topic?.slug}`}>
              {`${data?.topic?.title}`}
            </a>
          </h6>{" "}
        </>
      );
    } else if (action === "move") {
      return (
        <>
          <h6>Moved Topic Replies!</h6>
          <p className="m-0">
            {`${content}`} moved some replies content from this{" "}
            <a
              href={`/community/${data?.fromCategorySlug?.categorySlug}/${data?.fromTopicInfo?.slug}`}
            >{`${data?.fromTopicInfo?.title}`}</a>{" "}
            topic to this{" "}
            <a
              href={`/community/${data?.topic?.toCategorySlug}/${data?.toTopicInfo?.slug}`}
            >{`${data?.toTopicInfo?.title}`}</a>{" "}
            topic.
          </p>
        </>
      );
    } else if (action === "flag") {
      return (
        <>
          <h6>
            <a
              href={`/community/${data?.categorySlug}/${data?.topicInfo?.slug}`}
            >{`${data?.topicInfo?.title}`}</a>
          </h6>{" "}
          <p className="m-0">
            {`${content} ${action}`} this reply "{`${data?.replyInfo?.content}`}
            ".
          </p>
        </>
      );
    }
  } else if (item === "community") {
    let platformActions = [
      "join_request_sent",
      "invitation_sent",
      "request_accepted",
      "request_declined",
      "removed_members",
      "add",
      "edit",
      "delete",
      "reward_settings",
      "activityReferral_settings",
      "rewardAndReferral_settings",
      "theme_settings",
      "join",
      "leave",
      "publish"
    ];
    if (platformActions.includes(action)) {
      if (action === "join_request_sent") {
        action = "sent request to join";
      } else if (action === "invitation_sent") {
        action = "you invited to join";
      } else if (action === "request_accepted" || action === "join") {
        action = "successfully joined";
      } else if (action === "request_declined") {
        action = "join request cancelled for";
      } else if (action === "removed_members") {
        action = "removed from";
      } else if (action === "reward_settings") {
        action = "updated reward points of";
      } else if (action === "activityReferral_settings") {
        action = "updated referral points of";
      } else if (action === "rewardAndReferral_settings") {
        action = "updated activity earned referral points of";
      } else if (action === "theme_settings") {
        action = "updated theme colors of";
      } else if (action === "leave") {
        action = "left";
      } else if (action === "edit") {
        action = "update";
      } else if (action === "add") {
        action = "add";
      } else {
        action = action + "ed";
      }
      return (
        data?.communityInfo?.slug && (
          <>
            <h6>
              {`${content} ${action}`}{" "}
              <a
                href={`/community/${data?.communityInfo?.slug}/overview`}
              >{`${data?.communityInfo?.communityName}`}</a>
              .
            </h6>{" "}
            {/* <p>{`${content} ${action}`} this project.</p> */}
          </>
        )
      );
    }
  } else if (item === "topic") {
    if (
      action === "add" ||
      action === "edit" ||
      action === "delete" ||
      action === "lock" ||
      action === "unlock" ||
      action === "pin" ||
      action === "unpin" ||
      action === "up" ||
      action === "down" ||
      action === "subscribe" ||
      action === "unsubscribe"
    ) {
      if (action === "up") {
        action = "liked";
      } else if (action === "down") {
        action = "unlike";
      } else if (action === "subscribe") {
        action = "subscribed";
      } else if (action === "unsubscribe") {
        action = "unsubscribed";
      } else {
        action = action + "ed";
      }
      return (
        <>
          <h6 className="text-ellipsis-2line line-height-normal">
            {/* {`${content} ${action}`} this topic. */}
            {`${content} ${action}`}{" "}
            <a
              className="text-ellipsis-2line line-height-normal d-inline"
              href={`/topic-detail/${data?.communityInfo?.slug}/${data?.slug}`}
            >{`${data?.title}`}</a>{" "}
            in{" "}
            <a
              className="text-ellipsis-2line line-height-normal d-inline"
              href={`/community/${data?.communityInfo?.slug}/overview`}
            >{`${data?.communityInfo?.communityName}`}</a>
            .
          </h6>
        </>
      );
    } else if (action === "moved") {
      return (
        <>
          <h6>
            {`${content} ${action}`}{" "}
            <a
              href={`/topic-detail/${data?.communityInfo?.slug}/${data?.topicInfo?.slug}`}
            >{`${data?.topicInfo?.title}`}</a>{" "}
            from {`${data?.oldCategoryInfo?.categoryName}`} to{" "}
            {`${data?.categoryInfo?.categoryName}`}.
          </h6>{" "}
          {/* <p>
            {`${content} ${action}`}ed this topic from this category{" "}
            {`${data.oldCategoryInfo.categoryName}`} to this category{" "}
            {`${data.categoryInfo.categoryName}`}.
          </p> */}
        </>
      );
    }
  } else if (item === "user") {
    if (
      action === "follow" ||
      action === "unfollow" ||
      action === "add_friend" ||
      action === "remove_friend" ||
      action === "accept_friend" ||
      action === "flag" ||
      action === "email_verified"
    ) {
      if (action === "add_friend") {
        action = "sent friend request to ";
      } else if (action === "remove_friend") {
        action = "removed or cancelled the freind request from ";
      } else if (action === "accept_friend") {
        action = "accepted friend request from ";
      } else if (action === "email_verified") {
        action = "verified the email and profile activated and also joined with this user network";
      } else {
        action = action + "ed";
      }
      return (
        <>
          <h6>
            {`${content} ${action}`}{" "}
            <a href={`/members/${user?.username}`}>{`${user?.fullname}`}</a>
          </h6>
        </>
      );
    }
  } else if (item === "reward") {
    if (action === "like_topic") {
      return (
        <>
          <h6>
            <a href={`/community/${data?.categorySlug}/${data?.slug}`}>{`${data?.title}`}</a>
          </h6>{" "}
          <p className="m-0">
            {`${content} earned ${data?.earned} reward points for liked the topic.`}.
          </p>
        </>
      );
    } else if (action === "subscribe_topic") {
      return (
        <>
          <h6>
            <a href={`/community/${data?.categorySlug}/${data?.slug}`}>{`${data?.title}`}</a>
          </h6>{" "}
          <p className="m-0">
            {`${content} earned ${data?.earned} reward points for subscribed the topic.`}.
          </p>
        </>
      );
    } else if (action === "like_reply") {
      return (
        <>
          <h6>
            <a href={`/community/${data?.categorySlug}/${data?.slug}`}>{`${data?.title}`}</a>
          </h6>{" "}
          <p className="m-0">
            {`${content} earned ${data?.earned} reward points for liked this reply "${data?.content}" content.`}
            .
          </p>
        </>
      );
    } else if (action === "add_reply") {
      return (
        <>
          <h6>
            <a href={`/community/${data?.categorySlug}/${data?.slug}`}>{`${data?.title}`}</a>
          </h6>{" "}
          <p className="m-0">
            {`${content} earned ${data?.earned} reward points for added the new reply "${data?.content}" content.`}
            .
          </p>
        </>
      );
    } else if (action === "course_completed") {
      return (
        <>
          <h6>
            <a href={`/course/${data?.categorySlug}/${data?.slug}`}>{`${data?.title}`}</a>
          </h6>{" "}
          <p className="m-0">
            {`${content} earned ${data?.earned} reward points for completed the resource successfully.`}
            .
          </p>
        </>
      );
    } else if (action === "new_user_registered") {
      action =
        "earned " +
        data.earned +
        " reward points for user joined with your referral code or from your network";
      return (
        <>
          <h6>
            <a
              href={`/members/${user?.username}`}
              className="font-weight-bold fweight700"
            >{`${user?.fullname}`}</a>
          </h6>
          <p className="m-0">{`${content} ${action}`} </p>
        </>
      );
    } else if (action === "login") {
      return (
        <>
          <h6>{`${content} earned ${data?.earned} reward points for the daily login activity.`}</h6>
        </>
      );
    }
  }
};

export const replaceHashWordsWithSpan = (content = "") => {
  const formattedContent = content.replace(/(^|\s)(#[^\s#]+)/g, (match, space, word) => {
    // Remove the leading #
    const wordWithoutHash = word.substring(1);
    // Replace with <span>word</span>
    return `${space}<span class='font-weight-bold'>#${wordWithoutHash}</span>`;
  });

  return formattedContent;
};
// export const replaceHashWordsWithSpan = (text) => {
//   const formattedText = text.replace(/(^|\s)(#[^\s#]+)\b/g, "$1<strong>$2</strong>");

//   return formattedText;
// };

export const createNotificationStatement = (data) => {
  let item = data.item;
  let action = data.action;
  let ownerId = data.ownerId ? data.ownerId : 0;
  let owner = data.ownerInfo ? data.ownerInfo[0] : {};
  let sender = data.senderInfo ? data.senderInfo[0] : {};
  let user = data.userInfo ? data.userInfo[0] : {};

  let content = owner ? owner.fullname : "You ";
  // if (
  //   item === "course_category" &&
  //   (action === "add" || action === "edit" || action === "delete")
  // ) {
  //   return (
  //     <>
  //       <h6>{`${data.categoryName}`}</h6> <p>{`${content} ${action}`}ed this Resource Category.</p>
  //     </>
  //   );
  // } else
  if (item === "reply") {
    if (action === "add" || action === "up") {
      if (action === "up") {
        action = "liked";
      } else {
        action = action + "ed";
        return (
          <>
            {" "}
            <h6>
              <a href={`/members/${data?.ownerInfo[0]?.username}`}>{`${content}`} </a>
              {action !== "edited" ? <>commented</> : <>edited comment</>} on{" "}
              <a href={`/topic-detail/${data?.communityInfo?.slug}/${data?.topic?.slug}`}>
                {`${data?.topic?.title}`}
              </a>
            </h6>{" "}
            <h6>
              {/* {`${content} ${action}`} this reply " {`${data.reply.content}`}" Content. */}
              {`${data?.reply?.content}`}
            </h6>
          </>
        );
      }
      return (
        <>
          <h6>
            <a href={`/members/${user.username}`}>{`${content}`}</a>
            {` ${action}`} this reply " {`${data?.reply?.content}`}" on{" "}
            <a href={`/topic-detail/${data?.communityInfo?.slug}/${data?.topic?.slug}`}>
              {`${data?.topic?.title}`}
            </a>
          </h6>{" "}
        </>
      );
    }
  } else if (item === "community") {
    let platformActions = [
      "join_request_sent",
      "invitation_sent",
      "request_accepted",
      // "request_declined",
      // "removed_members",
      "add",
      "edit",
      // "delete",
      // "reward_settings",
      // "refferal_settings",
      // "theme_settings",
      "join",
      "leave",
      "publish"
    ];
    if (platformActions.includes(action)) {
      if (action === "join_request_sent") {
        action = "sent request to join";
      } else if (action === "invitation_sent") {
        action = "you invited to join";
      } else if (action === "request_accepted" || action === "join") {
        action = "successfully joined";
      } else if (action === "edit") {
        action = "edit";
      }
      // else if (action === "request_declined") {
      //   action = "join request cancelled for";
      // } else if (action === "removed_members") {
      //   action = "removed from";
      // } else if (action === "reward_settings") {
      //   action = "updated reward points of";
      // } else if (action === "refferal_settings") {
      //   action = "updated referral points of";
      // } else if (action === "theme_settings") {
      //   action = "updated theme colors of";
      // }
      else if (action === "leave") {
        action = "left";
      } else {
        action = action + "ed";
      }
      return (
        data?.communityInfo?.slug && (
          <>
            <h6>
              <a href={`/members/${data?.ownerInfo[0]?.username}`}>{`${content}`}</a>
              {` ${action}`}{" "}
              <a
                href={`/community/${data?.communityInfo?.slug}/overview`}
              >{`${data?.communityInfo?.communityName}`}</a>
              .
            </h6>{" "}
            {/* <p>{`${content} ${action}`} this project.</p> */}
          </>
        )
      );
    }
  } else if (item === "topic") {
    if (
      // action === "add" ||
      // action === "edit" ||
      // action === "delete" ||
      // action === "lock" ||
      // action === "unlock" ||
      // action === "pin" ||
      // action === "unpin" ||
      action === "up" ||
      // action === "down" ||
      action === "subscribe"
      // ||
      // action === "unsubscribe"
    ) {
      if (action === "up") {
        action = "liked";
      }
      // else if (action === "down") {
      //   action = "disliked";
      // }
      else if (action === "subscribe") {
        action = "subscribed";
      }
      // else if (action === "unsubscribe") {
      //   action = "unsubscribed";
      // }
      else {
        action = action + "ed";
      }
      return (
        <>
          <h6 className="text-ellipsis-2line line-height-normal">
            {/* {`${content} ${action}`} this topic. */}
            <a
              className="text-ellipsis-2line line-height-normal d-inline"
              href={`/members/${data?.ownerInfo[0]?.username}`}
            >{`${content}`}</a>{" "}
            {` ${action}`} your{" "}
            <a
              className="text-ellipsis-2line line-height-normal d-inline"
              href={`/topic-detail/${data?.communityInfo?.slug}/${data?.slug}`}
            >{`${data.title}`}</a>{" "}
            in{" "}
            <a
              className="text-ellipsis-2line line-height-normal d-inline"
              href={`/community/${data?.communityInfo?.slug}/overview`}
            >{`${data?.communityInfo?.communityName}`}</a>
            .
          </h6>
        </>
      );
    }
    //  else if (action === "moved") {
    //   return (
    //     <>
    //       <h6>
    //         {`${content} ${action}`}{" "}
    //         <a
    //           href={`/topic-detail/${data?.communityInfo?.slug}/${data?.topicInfo?.slug}`}
    //         >{`${data.topicInfo.title}`}</a>{" "}
    //         from {`${data?.oldCategoryInfo?.categoryName}`} to{" "}
    //         {`${data?.categoryInfo?.categoryName}`}.
    //       </h6>{" "}
    //       {/* <p>
    //         {`${content} ${action}`}ed this topic from this category{" "}
    //         {`${data.oldCategoryInfo.categoryName}`} to this category{" "}
    //         {`${data.categoryInfo.categoryName}`}.
    //       </p> */}
    //     </>
    //   );
    // }
  } else if (item === "user") {
    if (
      action === "follow" ||
      // action === "unfollow" ||
      action === "add_friend" ||
      // action === "remove_friend" ||
      action === "accept_friend"
      // action === "flag" ||
      // action === "email_verified"
    ) {
      if (action === "add_friend") {
        action = "sent you a friend request";
      }
      // else if (action === "remove_friend") {
      //   action = "removed or cancelled the freind request from ";
      // }
      else if (action === "accept_friend") {
        action = "accepted your friend request";
      }
      // else if (action === "email_verified") {
      //   action = "verified the email and profile activated and also joined with this user network";
      // }
      else if (action === "follow") {
        action = "following you";
      }
      // else {
      //   action = action + "ed you";
      // }
      return (
        <>
          <h6>
            <a href={`/members/${data?.ownerInfo[0]?.username}`}>{`${content}`}</a>
            {` ${action}`}
            {/* <a href={`/members/${user.username}`}>{`${user.fullname}`}</a> */}
          </h6>
        </>
      );
    }
  }
  // else if (item === "reward") {
  //   if (action === "like_topic") {
  //     return (
  //       <>
  //         <h6>
  //           <a href={`/community/${data.categorySlug}/${data.slug}`}>{`${data.title}`}</a>
  //         </h6>{" "}
  //         <p>{`${content} earned ${data.earned} reward points for liked the topic.`}.</p>
  //       </>
  //     );
  //   } else if (action === "subscribe_topic") {
  //     return (
  //       <>
  //         <h6>
  //           <a href={`/community/${data.categorySlug}/${data.slug}`}>{`${data.title}`}</a>
  //         </h6>{" "}
  //         <p>{`${content} earned ${data.earned} reward points for subscribed the topic.`}.</p>
  //       </>
  //     );
  //   } else if (action === "like_reply") {
  //     return (
  //       <>
  //         <h6>
  //           <a href={`/community/${data.categorySlug}/${data.slug}`}>{`${data.title}`}</a>
  //         </h6>{" "}
  //         <p>
  //           {`${content} earned ${data.earned} reward points for liked this reply "${data.content}" content.`}
  //           .
  //         </p>
  //       </>
  //     );
  //   } else if (action === "add_reply") {
  //     return (
  //       <>
  //         <h6>
  //           <a href={`/community/${data.categorySlug}/${data.slug}`}>{`${data.title}`}</a>
  //         </h6>{" "}
  //         <p>
  //           {`${content} earned ${data.earned} reward points for added the new reply "${data.content}" content.`}
  //           .
  //         </p>
  //       </>
  //     );
  //   } else if (action === "course_completed") {
  //     return (
  //       <>
  //         <h6>
  //           <a href={`/course/${data.categorySlug}/${data.slug}`}>{`${data.title}`}</a>
  //         </h6>{" "}
  //         <p>
  //           {`${content} earned ${data.earned} reward points for completed the resource successfully.`}
  //           .
  //         </p>
  //       </>
  //     );
  //   } else if (action === "new_user_registered") {
  //     action =
  //       "earned " +
  //       data.earned +
  //       " reward points for user joined with your referral code or from your network";
  //     return (
  //       <>
  //         <h6>
  //           <a
  //             href={`/members/${user.username}`}
  //             className="font-weight-bold fweight700"
  //           >{`${user.fullname}`}</a>
  //         </h6>
  //         <p>{`${content} ${action}`} </p>
  //       </>
  //     );
  //   } else if (action === "login") {
  //     return (
  //       <>
  //         <h6>{`${content} earned ${data.earned} reward points for the daily login activity.`}</h6>
  //       </>
  //     );
  //   }
  // }
};
export const handleScroll = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth"
  });
};

export const isValidateDOB = () => {
  var date = new Date();
  var dd = date.getDate();
  var mm = date.getMonth() + 1;
  var yyyy = date.getFullYear();

  //Add a zero if one Digit (eg: 05,09)
  if (dd < 10) {
    dd = "0" + dd;
  }
  //Add a zero if one Digit (eg: 05,09)
  if (mm < 10) {
    mm = "0" + mm;
  }
  let minYear = yyyy - 80; //Calculate Minimun Age (<80)
  let maxYear = yyyy - 18; //Calculate Maximum Age (>18)

  var min = minYear + "-" + mm + "-" + dd;
  var max = maxYear + "-" + mm + "-" + dd;
  return { min, max };
};

export const areAllFieldsNull = (objectSample) => {
  const values = Object.values(objectSample);
  return values.every((value) => value === null);
};

export const convertHexWithNonZeroStart = (hexString) => {
  const num = parseInt(hexString, 16);
  const convertedHexString = "0x" + num.toString(16);
  return convertedHexString;
};

export const sleep = (ms) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(), ms);
  });
};

export const appendS3UrlToPath = (path) => `${S3_URL}${path}`;

export const retryUntilSuccess = async (
  methodToRetry = async () => {},
  methodArgs = [],
  initDelayInMs = 0,
  retries = 30,
  retryDelayInMs = 2000
) => {
  if (initDelayInMs > 0) {
    await new Promise((resolve) => setTimeout(resolve, initDelayInMs));
  }
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const value = await methodToRetry(...methodArgs);
      return value;
    } catch (error) {
      if (attempt === retries) {
        throw error;
      }
      await new Promise((resolve) => setTimeout(resolve, retryDelayInMs));
    }
  }
};

export const checkMediFile = (fileName) => {
  if (!fileName) {
    return "";
  }
  if (documentTypes.image.some((item) => fileName.includes(item))) {
    return "Image";
  } else if (documentTypes.document.some((item) => fileName.includes(item))) {
    return "Document";
  } else if (documentTypes.text.some((item) => fileName.includes(item))) {
    return "Txt";
  }
};

export const compareTimesWithNow = (selectedTime, timeZone) => {
  const zoneTime = toMomentTimeZone(selectedTime, timeZone);
  if (zoneTime.isBefore(momentTz().tz(timeZone))) {
    return "past";
  } else {
    return "future";
  }
};

export const compareTimeWithSecondTime = (timeOne, timeTwo, timeZone) => {
  const timeOneToDate = toMomentTimeZone(timeOne, timeZone);
  const timeTwoToDate = toMomentTimeZone(timeTwo, timeZone);
  if (timeOneToDate.isBefore(timeTwoToDate)) {
    return "past";
  } else {
    return "future";
  }
};

export const toMomentTimeZone = (time, timeZone) => {
  const zoneTime = momentTz.tz(timeZone);
  const timeOneToDate = new Date(time);
  return zoneTime.set({
    year: timeOneToDate.getFullYear(),
    month: timeOneToDate.getMonth(),
    date: timeOneToDate.getDate(),
    hour: timeOneToDate.getHours(),
    minute: timeOneToDate.getMinutes(),
    second: timeOneToDate.getSeconds(),
    millisecond: timeOneToDate.getMilliseconds()
  });
};
