import * as AuthTypes from "./types";
const initialAuthDetails = {
  user: JSON.parse(localStorage.getItem("userDetails")) || {
    userId: null
  },
  isLoggedIn: JSON.parse(localStorage.getItem("userDetails"))?.isLoggedIn || false
};
const initialState = {
  accessToken: "",
  avatar: "",
  userId: "",
  referralBy: null,
  email: "",
  phoneNumber: "",
  tfa: 0,
  firstName: "",
  lastName: "",
  fullName: "",
  isIpWhiteList: 0,
  isActive: 0,
  isKyc: 0,
  username: "",
  tagLine: "",
  isLocked: 0,
  referralCode: "",
  usergroup: 0,
  isOnline: 1,
  privilege: 1,
  messageCount: 0,
  notification: {},
  paymentModeDetail: null,
  ...initialAuthDetails.user,
  isLoggedIn: initialAuthDetails.isLoggedIn
};
const authReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case AuthTypes.LOGIN_USER: {
      const authDetails = {
        isLoggedIn: true,
        ...payload,
        paymentModeDetail: payload.paymentModeDetail
      };
      localStorage.setItem("userDetails", JSON.stringify(authDetails));
      return {
        ...state,
        ...authDetails
      };
    }
    case AuthTypes.UPDATE_USER_DATA: {
      const authDetails = {
        ...payload
      };
      localStorage.setItem("userDetails", JSON.stringify(authDetails));
      return {
        ...state,
        ...authDetails
      };
    }

    case AuthTypes.UPDATE_MESSAGE_COUNT:
      return {
        ...state,
        messageCount: payload.messageCount
      };
    case AuthTypes.LOGOUT_USER:
      return {
        ...initialState,
        isLoggedIn: false
      };
    case AuthTypes.GET_NOTIFICATION:
      return {
        ...state,
        notification: payload
      };
    default:
      return state;
  }
};

export default authReducer;
