import { commonSlice } from "./reducers";

export const {
  setSnackbar,
  setSpinner,
  setProfileActiveTab,
  setCommunityFilterQuery,
  setResourceFilterQuery,
  setLoader
} = commonSlice.actions;
export default commonSlice.actions;
