import { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { handleScroll } from "../utils";

const ScrollToTop = ({ children, location: { pathname } }) => {
  useEffect(() => {
    handleScroll();
  }, [pathname]);

  return children || null;
};

export default withRouter(ScrollToTop);
