import _ from "lodash";

// get element by selector
export const getElem = (selector) => {
  return document.querySelector(selector);
};

// get element by selector all
export const getElems = (selector) => {
  return document.querySelectorAll(selector);
};

// class add function
export const addClass = (elem, ...classNames) => {
  let _newElem = elem;
  if (_.isString(_newElem)) {
    _newElem = getElems(elem);
  }
  if (_newElem.length !== undefined) {
    _.forEach(_newElem, function (elem) {
      _.forEach(classNames, function (className) {
        elem.classList.add(className);
      });
    });
  } else {
    _.forEach(classNames, function (className) {
      _newElem.classList.add(className);
    });
  }
};

// class remove function
export const removeClass = (elem, ...className) => {
  let _newElem = elem;
  if (_.isString(_newElem)) {
    _newElem = getElems(elem);
  }
  if (_newElem.length !== undefined) {
    _.forEach(_newElem, function (elem) {
      _.forEach(className, function (className) {
        elem.classList.remove(className);
      });
    });
  } else {
    _.forEach(className, function (className) {
      _newElem.classList.remove(className);
    });
  }
};

// get attribute value
export const getAttr = function (elem, attr) {
  return elem.getAttribute(attr);
};

// set attribute value
export const setAttr = function (elems, object) {
  let _newElem = elems;
  if (_.isString(_newElem)) {
    _newElem = getElems(elems);
  }
  _.forEach(_newElem, function (elem) {
    elem.setAttribute(object.prop, object.value);
  });
};

// remove attribute value
export const removeAttr = function (elem, attr) {
  elem.removeAttribute(attr);
};

// Update html content
export const setContent = function (selector, content) {
  let _newElem = selector;
  if (_.isString(_newElem)) {
    _newElem = getElems(selector);
  }
  if (typeof _newElem.length !== typeof undefined) {
    _.forEach(_newElem, function (elem) {
      const leftJoin =
        elem.getAttribute("data-leftJoin") !== null ? elem.getAttribute("data-leftJoin") : "";
      const rightJoin =
        elem.getAttribute("data-rightJoin") !== null ? elem.getAttribute("data-rightJoin") : "";
      elem.innerHTML = leftJoin + content + rightJoin;
    });
  }
};

export const copyToClipboard = async (value) => {
  let textToCopy;
  if (typeof value === "string") {
    textToCopy = value;
  } else {
    textToCopy = JSON.stringify(value, null, 4);
  }
  try {
    await navigator.clipboard.writeText(textToCopy);
  } catch (err) {
    console.error("Failed to copy: ", err);
  }
};

export const copyToClipboardUpdated = (value) => {
  let textToCopy;
  if (typeof value === "string") {
    textToCopy = value;
  } else {
    textToCopy = JSON.stringify(value, null, 4);
  }

  // Create a new textarea element and give it id='tempElement'
  const textarea = document.createElement("textarea");
  textarea.id = "tempElement";
  textarea.style.height = 0;
  document.body.appendChild(textarea);
  textarea.value = textToCopy;

  // Select the newly created element
  const selector = document.querySelector("#tempElement");
  selector.select();

  // Execute the copy command
  let successfulCopy;
  try {
    successfulCopy = document.execCommand("copy");
  } catch (err) {
    console.error("Failed to copy: ", err);
    successfulCopy = false;
  }
  // Remove the temporary element
  document.body.removeChild(textarea);
};
