export const GET_COMMUNITY = "GET_COMMUNITY";
export const GET_USERS = "GET_USERS";
export const GET_COMMUNITIES = "GET_COMMUNITIES";
export const SET_UNAUTHORIZED = "SET_UNAUTHORIZED";

export const SET_MODAL_CREATE_TOPIC = "SET_MODAL_CREATE_TOPIC";
export const GET_TOPICS = "GET_TOPICS";
export const SET_TAG_LIST = "SET_TAG_LIST";
export const GET_LEVEL_PERCENTAGE = "GET_LEVEL_PERCENTAGE";

export const GET_ICO_DETAILS = "GET_ICO_DETAILS";
export const GET_ALL_COMMUNITY_ICO = "GET_ALL_COMMUNITY_ICO";

export const GET_INVESTOR_PURCHASE = "GET_INVESTOR_PURCHASE";
export const GET_POPULAR_TAGS = "GET_POPULAR_TAGS";
export const GET_TRENDING_TOPICS = "GET_TRENDING_TOPICS";
export const GET_REWARD = "GET_REWARD";
export const GET_REWARD_POINTS = "GET_REWARD_POINTS";
export const GET_REFERRAL_LIST = "GET_REFERRAL_LIST";
export const SET_USERS_LIST = "SET_USERS_LIST";
export const SET_CATEGORIES_LIST = "SET_CATEGORIES_LIST";
export const SET_MY_FRIENDS = "SET_MY_FRIENDS";
export const RESET_ALL_STATES = "RESET_ALL_STATES";
export const SET_RESOURCE = "SET_RESOURCE";
export const SET_ALL_RESOURCE = "SET_ALL_RESOURCE";

export const RECOMMENDED_RESOURCE = "RECOMMENDED_RESOURCE";
export const UPDATE_THEME_COLOR = "UPDATE_THEME_COLOR";
export const SET_STRIPE_ACCOUNTS = "SET_STRIPE_ACCOUNTS";
export const SET_SELECTED_REWARD_MENU = "SET_SELECTED_REWARD_MENU";
