import AboutIcon from "./assets/icons/about-icon.svg";
import DiscussionIcon from "./assets/icons/discussion-icon.svg";
import GovernanceIcon from "./assets/icons/governance-icon.svg";
import IcoIcon from "./assets/icons/ico-icon.svg";
import MembersIcon from "./assets/icons/members-icon.svg";
import mytokensIcon from "./assets/icons/mytokens-icon.svg";
import OverviewIcon from "./assets/icons/profile/overview.svg";
import profileIcon from "./assets/icons/projectName.svg";
import ProposalIcon from "./assets/icons/proposal-icon.svg";
import ResourcesIcon from "./assets/icons/resources-icon.svg";
import FaqIcon from "./assets/icons/resources/faq.svg";
import ModuleIcon from "./assets/icons/resources/module.svg";
import RewardsIcon from "./assets/icons/rewards-icon.svg";
import {
  default as settingIcon,
  default as SettingOverview
} from "./assets/icons/setting-icon.svg";
import stakingIcon from "./assets/icons/staking-icon.svg";
import ManageCategoryIcon from "./assets/images/icon/manage-category.svg";
import ManageTheme from "./assets/images/icon/manage-theme.svg";
import rewardIcon from "./assets/images/icon/rewardIcon.svg";

import ClaimReward from "./assets/icons/ClaimReward.svg";

import { hexlify } from "ethers/lib/utils";

export const commentSortingOptions = {
  recent: { label: "Most recent", icon: "arrow_drop_down" },
  old: { label: "Old replies", icon: "arrow_drop_up" }
};
export const sortingOptions = {
  recent: { label: "Recent Posts ", icon: "arrow_drop_down" },
  old: { label: "Older Posts", icon: "arrow_drop_up" }
};
export const courseSortingOptions = {
  new: { label: "Recent", icon: "arrow_drop_down" },
  old: { label: "Old", icon: "arrow_drop_up" },
  popular: { label: "Popular", icon: "arrow_drop_up" },
  trending: { label: "Trending", icon: "arrow_drop_up" }
};
export const currencies = [{ label: "USD", value: "USD" }];

export const alphaNumeric = /[^0-9a-zA-Z]/;

export const onlyNumber = /^[0-9\b]+$/;

export const defaultReferralCode = "2e2e915eb07c5b74ceb6";

export const defaultCourseContentUrl =
  "/modules/introduction-to-cryptoati/the-2-most-powerful-concepts-for-making-and-multiplying-money";
export const DEFAULTCOURSEID = "631a9ae74c0c0812d640dab1";
export const DEFAULTCOURSECONTENTID = "631dff3b7b40cdd8ba9507f1";

export const affliateDomains = ["www.cryptoati.io", "www.influati.com", "www.treasurex.com"];

export const ENVIRONMENT = "DEV"; // PROD

// Demo
export const BASE_URL_ONE = "http://3.98.211.88:6002/";
// export const BASE_URL_TWO = "http://3.98.211.88:6001/";
export const SITE_URL = "http://3.98.211.88:4567/";
export const NFT_SALE_PAGE = "http://3.98.211.88:4001/";

// Live
// export const BASE_URL_ONE = "https://cryptoati.io/";
export const BASE_URL_TWO = "https://societi.io/";
// export const SITE_URL     = "https://cryptoati.io/";

//Local
// export const BASE_URL_ONE = "http://localhost:6002/";
// export const BASE_URL_TWO = "http://localhost:6001/";
// export const SITE_URL = "http://localhost:3000/";

// paths
//community
export const CONFIRM_MAIL = "/confirm-mail";
export const LOCK_SCREEN = "/lock-screen";
export const RECOVER_PW = "/forgot-password/:email?/:token?";
export const VERIFY_ACCOUNT = "/account-verify";
export const APPROVE_IP = "/approveIp";
export const SIGNIN = "/login";
export const EMAILID = "/";
export const SIGNUP = "/signup";
export const JOINCOMMUNITY = "/join";
export const ERROR404 = "/error404";
export const ERROR500 = "/error500";
export const HOME = "/digital-key-sale";
export const WELCOME = "/communities";
//export const COMMUNITYOVERVIEW = "/communities-overview";
export const ABOUT = "/about";
export const EARNINGSDISCLAIMER = "/earnings-disclaimer";
export const PRIVACYPOLICY = "/privacy-policy";
export const TERMSOFSERVICES = "/terms-for-services";
export const PROFILE = "/members/:username?/:tab?/:action?";
export const OTHER_MEMBER_PROFILE = "/searchMembers/:username?/:tab?";
export const MEMBERS = "/members";
export const FRIENDLIST = "/friend-list";
export const MESSAGING = "/message";
export const HOMEFORUM = "/forum-categories";
export const NOTIFICATION = "/notifications";
export const SEARCH = "/search";
export const COURSES = "/member-resources";
export const COURSE_DETAIL = "/training/:category_slug/:course_title_slug";
export const COURSE_CONTENT_DETAIL = "/modules/:course_title_slug/:course_content_title_slug";
export const COURSE_ADD_EDIT = "/course/action/:action/:communityId?/:id?";
export const TOPIC_DETAIL = "/topic-detail/:community_title_slug/:topic_title_slug/:mode?";
export const PAYMENT_STATUS = "/payment-status";
export const TOPIC_DETAIL_BASE_URL = "/topic-detail";
export const COMMUNITY_DETAIL_BASE_URL = "/community";
export const REFERRAL_LANDING_PAGE = "/vip-invite";
export const DASHBOARD = "/communities";
export const COMMUNITYDETAIL = "/community/:community_title_slug/:action/:id?/:moduleId?";
export const TOP_COMMUNITY = "/communities/topcommunities";
export const SUBMITED_COMMUNITY = "/communities/submitedcommunities";
export const MY_PROJECTS = "/communities/myprojects";
export const MANAGED_PROJECTS = "/communities/managedprojects";
export const FEATURED_COMMUNITY = "/communities/featuredcommunity";

export const MY_INVITATIONS = "/communities/myinvitations";
export const COMMUNITY_OVERVIEW_EDIT = "/community-overview-edit/:community_title_slug";
export const COMMUNITY_CREATE_PROJECT = "/community/create-project";
export const ADD_PAYMENT_MODE = "/payment/addPaymentMode";
export const GET_PAYMENT_MODE_DETAIL = "/payment/getPaymentModeDetail";

export const COMMUNITYDETAIL_ACTIONS = {
  overview: "overview",
  resources: "resources",
  governance: "governance",
  members: "members",
  staking: "staking",
  ico: "ico",
  discussion: "discussion",
  settings: "settings",
  mytokens: "mytokens",
  rewards: "rewards",
  review: "review",
  createResource: "create-resource",
  editResource: "edit-resource",
  resourcesDetail: "resource-detail",
  resourcesModule: "resource-module",
  transactions: "transactions"
};
export const DISCOVER = "/communities/discover";
export const HELPANDSUPPORT = "/help-and-support";
export const SEARCHRESULTS = "/search-results";
export const S3_URL = "https://cryptoati-media.s3.us-west-2.amazonaws.com/";
export const tabs = [
  { id: "overview", label: "Overview", icon: OverviewIcon, Permission: 0, routes: ["overview"] },
  { id: "review", label: "Review", icon: OverviewIcon, Permission: 2, routes: ["review"] },
  {
    id: "discussion",
    label: "Discussion",
    icon: DiscussionIcon,
    Permission: 0,
    routes: ["discussion"]
  },
  {
    id: "resources",
    label: "Resources",
    icon: ResourcesIcon,
    Permission: 0,
    routes: ["resources", "resource-detail", "edit-resource", "create-resource", "resource-module"]
  },
  { id: "members", label: "Members", icon: MembersIcon, Permission: 0, routes: ["members"] },
  {
    id: "governance",
    label: "Governance",
    icon: GovernanceIcon,
    Permission: 0,
    routes: ["governance"]
  },
  { id: "ico", label: "ICO", icon: IcoIcon, Permission: 0, routes: ["ico"] },
  { id: "rewards", label: "Rewards", icon: RewardsIcon, Permission: 0, routes: ["rewards"] },
  { id: "settings", label: "Settings", icon: SettingOverview, Permission: 2, routes: ["settings"] },
  { id: "staking", label: "Staking", icon: stakingIcon, Permission: 0, routes: ["staking"] },
  { id: "mytokens", label: "My Tokens", icon: mytokensIcon, Permission: 0, routes: ["mytokens"] },
  {
    id: "transactions",
    label: "Transactions",
    icon: "grommet-icons:transaction",
    Permission: 2,
    routes: ["transactions"]
  }
  // Add other tabs here
];
export const resourcesTabs = [
  { id: "overview", label: "Overview", icon: OverviewIcon, Permission: 0 },
  { id: "modules", label: "Modules", icon: ModuleIcon, Permission: 0 },
  { id: "faqs", label: "Faqs", icon: FaqIcon, Permission: 0 }
];

export const resourcesData = [
  {
    id: 1,
    title: "Introduction to JavaScript",
    description: "Learn the basics of JavaScript programming language.",
    noOfModules: 8,
    userCompleted: 18,
    AmountStatus: "Paid",
    status: "Draft",
    modules: [1, 3, 5]
  },
  {
    id: 2,
    title: "Python for Beginners",
    description: "A beginner's guide to Python programming.",
    noOfModules: 6,
    userCompleted: 28,
    AmountStatus: "20$",
    status: "Published",
    modules: [4, 2]
  },
  {
    id: 3,
    title: "Web Development Fundamentals",
    description: "Understanding core concepts of web development.",
    noOfModules: 7,
    userCompleted: 8,
    AmountStatus: "50$",
    status: "Draft",
    modules: [1, 2, 5]
  },
  {
    id: 4,
    title: "Data Science Essentials",
    description: "Essential concepts for getting started with data science.",
    noOfModules: 9,
    userCompleted: 38,
    AmountStatus: "Paid",
    status: "Published",
    modules: [1, 2, 3]
  },
  {
    id: 5,
    title: "Artificial Intelligence Explained",
    description: "An overview of artificial intelligence and its applications.",
    noOfModules: 5,
    userCompleted: 48,
    AmountStatus: "70$",
    status: "Draft"
  }
];

export const modules = [
  {
    id: 1,
    title: "Introduction to JavaScript",
    description: "Learn the basics of JavaScript programming language.",
    type: "video",
    audioLink: "",
    videoLink: "https://www.example.com/video/intro_to_js.mp4"
  },
  {
    id: 2,
    title: "Python for Beginners",
    description: "A beginner's guide to Python programming.",
    type: "video",
    audioLink: "",
    videoLink: "https://www.example.com/video/python_beginners.mp4"
  },
  {
    id: 3,
    title: "Web Development Fundamentals",
    description: "Understanding core concepts of web development.",
    type: "audio",
    audioLink: "https://www.example.com/audio/web_dev_fundamentals.mp3",
    videoLink: ""
  },
  {
    id: 4,
    title: "Data Science Essentials",
    description: "Essential concepts for getting started with data science.",
    type: "audio",
    audioLink: "https://www.example.com/audio/data_science_essentials.mp3",
    videoLink: ""
  },
  {
    id: 5,
    title: "Artificial Intelligence Explained",
    description: "An overview of artificial intelligence and its applications.",
    type: "video",
    audioLink: "",
    videoLink: "https://www.example.com/video/ai_explained.mp4"
  }
];
// Mobile view Route
export const CREATEEDITTOPIC = "/topic/:action/:communityId?/:topic_title_slug?";

//messaging
export const SCREEN1 = "/screen1";
export const SCREEN2 = "/screen2";
export const SCREEN3 = "/screen3";

export const COMMUNITY_URLS = [
  HOME,
  PROFILE,
  FRIENDLIST,
  MESSAGING,
  COURSES,
  TOPIC_DETAIL,
  TOPIC_DETAIL_BASE_URL
];

//admin
export const ADMIN = "/admin";

export const ADMIN_PROJECT_CATEGORIES = "/admin/project-categories";
export const ADMIN_ADD_EDIT_PROJECT_CATEGORIES = "/admin/project-categories/:action/:id?";

export const ADMIN_PROJECT_LIST = "/admin/projects";

export const ADMIN_FORUM_CATEGORIES = "/admin/forum-categories";
export const ADMIN_ADD_EDIT_FORUM_CATEGORIES = "/admin/forum-categories/:action/:communityId/:id?";

export const ADMIN_USERS = "/admin/users";
export const ADMIN_USER_ADD_EDIT = "/admin/users/:action/:id?";

export const ADMIN_TOPIC_LIST = "/admin/topics";
export const ADMIN_REPLY_LIST = "/admin/replies";
export const ADMIN_REPORT_LIST = "/admin/flags";
export const ADMIN_SUBSCRIPTION_LIST = "/admin/user-subscribtions";

export const ADMIN_TAG_LIST = "/admin/tags";
export const ADMIN_TAG_ADD_EDIT = "/admin/tags/:action/:id?";

export const ADMIN_RESOURCE_CATEGORIES = "/admin/resource-categories";
export const ADMIN_ADD_EDIT_RESOURCE = "/admin/resource-categories/:action/:id?";

export const ADMIN_RESOURCE_LIST = "/admin/resources";

export const ADMIN_EMAIL_TEMPLATES_LIST = "/admin/email-templates";
export const ADMIN_ADD_EDIT_EMAIL_TEMPLATES = "/admin/email-templates/:action/:id?";

export const ADMIN_PAGE_LIST = "/admin/pages";
export const ADMIN_ADD_EDIT_PAGE = "/admin/pages/:action/:id?";
export const ADMIN_USER_LEVELS_LIST = "/admin/user-levels";
export const ADMIN_ADD_EDIT_USER_LEVELS = "/admin/user-levels/:action/:id?";

export const ADMIN_URLS = [
  ADMIN,
  ADMIN_FORUM_CATEGORIES,
  ADMIN_ADD_EDIT_FORUM_CATEGORIES,
  ADMIN_TOPIC_LIST,
  ADMIN_REPLY_LIST,
  ADMIN_REPORT_LIST,
  ADMIN_SUBSCRIPTION_LIST,
  ADMIN_USERS,
  ADMIN_USER_ADD_EDIT,
  ADMIN_TAG_LIST,
  ADMIN_TAG_ADD_EDIT,
  ADMIN_RESOURCE_CATEGORIES,
  ADMIN_ADD_EDIT_RESOURCE,
  ADMIN_RESOURCE_LIST,
  ADMIN_EMAIL_TEMPLATES_LIST,
  ADMIN_ADD_EDIT_EMAIL_TEMPLATES,
  ADMIN_PAGE_LIST,
  ADMIN_ADD_EDIT_PAGE,
  ADMIN_USER_LEVELS_LIST,
  ADMIN_ADD_EDIT_USER_LEVELS,
  ADMIN_PROJECT_CATEGORIES,
  ADMIN_ADD_EDIT_PROJECT_CATEGORIES,
  ADMIN_PROJECT_LIST
];

//export const SVGIcon = [{HomeIcon: '<svg width={30} height={30} fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M9 15c2.902 0 5.25-2.46 5.25-5.5S11.902 4 9 4 3.75 6.46 3.75 9.5 6.098 15 9 15Zm3.6 1.571h-.39a7.11 7.11 0 0 1-3.21.786c-1.153 0-2.231-.294-3.21-.786H5.4c-2.981 0-5.4 2.534-5.4 5.658v1.414C0 24.944 1.008 26 2.25 26h13.5c1.242 0 2.25-1.056 2.25-2.357v-1.414c0-3.124-2.419-5.658-5.4-5.658ZM22.5 15c2.484 0 4.5-2.112 4.5-4.714 0-2.603-2.016-4.715-4.5-4.715S18 7.683 18 10.286C18 12.888 20.016 15 22.5 15Zm2.25 1.571h-.178c-.652.236-1.34.393-2.072.393-.731 0-1.42-.157-2.072-.393h-.178c-.956 0-1.837.29-2.61.757a7.374 7.374 0 0 1 1.86 4.9v1.886c0 .108-.023.211-.028.315h8.278c1.242 0 2.25-1.056 2.25-2.358 0-3.04-2.348-5.5-5.25-5.5Z" fill="#e3e3e3" /></svg>'}];

export const GENERATION_LAMDA_API =
  "https://kzugfta2e6zzwfnvf4r43w7zci0mipli.lambda-url.ca-central-1.on.aws";
export const API_CALL_LIMITATION = 10;
export const CATEGORY_ID = "64ca3ac0f5406bdf6a2f6aa1";

//END POINTS

export const GET_ALL_COMMUNITIES = "getCommunities";
export const GET_ALL_DISCOVER_COMMUNITIES = "getAllCommunities";
export const GET_FRIENDS = "getFriends";
export const GET_NOTIFICATIONS = "getNotifications";
export const GET_MY_INVITAIONS = "getMyInviations";
export const CREATE_COMMUNITY = "createCommunity";
export const ADD_MEMBER = "addMember";
export const DECLINE_JOIN_REQUEST = "declineJoinRequest";
export const UPLOAD_COMMUNITY_IMAGE = "uploadCommunityImage";
export const UPLOAD_COMMUNITY_DOCUMENT = "uploadDocumentForCommunity";
export const GET_COMMUNITY_BY_ID = "getCommunityDataById";
export const SEND_JOIN_REQUEST = "sendJoinRequest";
export const JOIN_COMMUNITY = "joinCommunity";
export const LEAVE_COMMUNITY = "leaveCommunity";
export const GET_USERS_LIST = "getFriendsRecommendation";
export const UPDATE_COMMUNITY_STATUS = "updateCommunityStatus";
export const CREATE_INVITATION = "createInvitation";
export const UPDATE_COMMUNITY = "updateCommunity";
export const GET_COMMUNITY_MEMBERS = "getCommunityMembers";
export const ADD_MEMBER_MANAGE_ACCESS = "addMemberManageAccess";
export const REMOVE_MEMBER_MANAGE_ACCESS = "removeMemberManageAccess";
export const GET_Rewards_REFERRAL_POINTS = "getPoints";
export const GET_REFERRAL_LIST = "getReferralList";
export const UPDATE_Rewards_REFERRAL_POINTS = "updatePoints";
export const GET_ALL_FORUM_CATEGORY = "getAllForumCategories";
export const GET_ALL_COURSE_CATEGORY = "getAllCourseCategories";
export const ADD_FORUM_CATEGORY = "addForumCategory";
export const EDIT_FORUM_CATEGORY = "updateForumCategory";
export const DELETE_FORUM_CATEGORY = "deleteForumCategoryById";
export const DECLINE_INVITATION = "declineInvitation";
export const ADD_REFERRAL_LEVELS = "addReferralLevels";
export const VIEW_REFERRAL_LEVELS = "viewReferralLevels";
export const GET_ICO_DETAILS = "getAllIco";
export const UPDATE_USER_DATA = "updateUserData";
export const ADD_COURSE = "addCourse";
export const GET_COURSE_BY_ID = "getCourseById";
export const ADD_MODULE = "addCourseContent";
export const GET_MODULES = "getCourseContentById";
export const UPDATE_COURSE = "updateCourse";
export const UPDATE_COURSE_STATUS = "publishCourse";
export const ENROLL_COURSE = "enrollCourse";
export const CONFIRMPAYMENT = "payment/confirmPayment";
export const CONFIRMPAYMENTBEFOREPAYMENT = "payment/newPayment";
export const RECOMMENDED_RESOURCE = "recommendCourse";
export const ADD_PROGRESS = "progress";
export const PURCHASE_HISTORY = "purchaseHistory";

// export const GET_ALL_COMMUNITY_ICO = "getAllIcoandCommunityData";
export const ADD_ICO = "addIcoDetails";
export const ADD_ICO_LOCKUP_INFO = "saveLockupDetails";
export const UPDATE_ICO_DETAILS = "updateIcoDetails";
export const GET_INVESTOR_PURCHASE_DETAILS = "getInvestorPurchaseDetails";
export const UPDATE_INVESTOR_PURCHASE_DETAILS = "updateInvestorPurschaseDetails";
export const GET_MULTIPLE_USER_DETAILS = "getMultipleUserDetails";
export const REVIEW_FLAG = "reviewFlag";
export const REPORTED_TOPIC_LIST = "flagList";
export const REMOVE_MEMBER = "removeMember";
export const TRANSACTION_HISTORY = "/payment/listTransactions";
export const NEW_PAYMENT_INTENT = "/payment/createPaymentIntent";

export const resourceValidation = {
  category: {
    minimum: 0,
    maximum: 100,
    required: true
  },
  otherCategory: {
    minimum: 0,
    maximum: 100,
    required: true
  },
  title: {
    minimum: 5,
    maximum: 200,
    required: true
  },
  language: {
    minimum: 0,
    maximum: 100,
    required: true
  },
  description: {
    minimum: 20,
    maximum: 3000,
    required: true
  },
  mediaFile: {
    minimum: 5,
    maximum: 100,
    required: true
  },
  courseType: {
    minimum: 0,
    maximum: 100,
    required: true
  },
  question: {
    minimum: 10,
    maximum: 500,
    required: true
  },
  answer: {
    minimum: 10,
    maximum: 500,
    required: true
  },
  moduleTitle: {
    minimum: 10,
    maximum: 200,
    required: true
  },
  moduleFile: {
    minimum: 0,
    maximum: 100,
    required: true
  },
  moduleDescription: {
    minimum: 10,
    maximum: 3000,
    required: true
  },
  moduleFileType: {
    minimum: 0,
    maximum: 3000,
    required: true
  },
  price: {
    minimum: 0,
    maximum: 3000,
    required: true
  },
  currency: {
    minimum: 0,
    maximum: 3000,
    required: true
  },
  VimeoId: {
    minimum: 5,
    maximum: 3000,
    required: true
  },
  apiKey: {
    minimum: 0,
    maximum: 100,
    required: true
  },
  paymentMode: {
    minimum: 0,
    maximum: 100,
    required: false
  }
};

export const validationValue = {
  documentFieldRequired: true,
  quickLinkFieldRequired: true,
  faqsFieldRequired: false,
  tagLine: {
    minimum: 10,
    maximum: 100,
    required: true
  },
  projectName: {
    minimum: 3,
    maximum: 50,
    required: true
  },
  description: {
    minimum: 100,
    maximum: 1000,
    required: true
  },
  founderName: {
    minimum: 5,
    maximum: 50,
    required: true
  },
  purposeGoal: {
    minimum: 200,
    maximum: 2000,
    required: false
  },
  keyHighlights: {
    minimum: 20,
    maximum: 150,
    required: true
  },
  communityGuideLine: {
    minimum: 50,
    maximum: 1000,
    required: false
  },
  membershipCriteria: {
    minimum: 20,
    maximum: 500,
    required: false
  },
  privacyAndProtection: {
    minimum: 50,
    maximum: 1000,
    required: false
  },
  documentLabel: {
    minimum: 3,
    maximum: 15,
    required: true
  },
  quickLinkLabel: {
    minimum: 3,
    maximum: 15,
    required: true
  },
  quickLinkUrl: {
    required: true
  },
  questionFAQs: {
    minimum: 10,
    maximum: 150,
    required: true
  },
  answerFAQs: {
    minimum: 10,
    maximum: 300,
    required: true
  },
  imageUpload: {
    required: true,
    type: [".png", ".jpg", ".jpeg"],
    minimum: 0,
    maximum: 5000000
  },
  documentUpload: {
    required: true,
    type: [".docx", ".pdf"],
    minimum: 0,
    maximum: 10000000
  },
  termsAndConditionsUrl: {
    required: true,
    type: [".docx", ".pdf"],
    minimum: 0,
    maximum: 10000000
  },
  settingInput: {
    minimum: 1,
    maximum: 100
  },
  categoryName: {
    minimum: 3,
    maximum: 50
  },
  projectTeamRole: {
    minimum: 3,
    maximum: 10,
    required: true
  }
};

export const roles = [
  {
    label: "project admin",
    value: "3",
    iconUrl: profileIcon,
    description: "Short description for Option 1"
  },
  {
    label: "project content manager",
    value: "2",
    iconUrl: profileIcon,
    description: "Short description for Option 1"
  },
  {
    label: "community members",
    value: "1",
    iconUrl: profileIcon,
    description: "Short description for Option 1"
  }
];

export const permission = {
  admin: { label: "platform admin", value: 5 },
  projectCreator: { label: "project creator", value: 4 },
  projectAdmin: { label: "project admin", value: 3 },
  contentManager: { label: "project content manager", value: 2 },
  member: { label: "community members", value: 1 },
  otherMember: { label: "other members", value: 0 }
};

export const userRole = {
  superAdmin: 1,
  contentManager: 2,
  registeredUser: 3
};

export const communityGovernanceMenu = [
  {
    id: "proposals",
    icon: ProposalIcon,
    label: "Proposals"
  },
  {
    id: "about",
    icon: AboutIcon,
    label: "About"
  },
  {
    id: "Settings",
    icon: settingIcon,
    label: "Settings",
    submenu: [
      {
        id: "settingstrategies",
        label: "Strategie(s)"
      },
      {
        id: "settingproposal",
        label: "Proposal"
      },
      {
        id: "settingvoting",
        label: "Voting"
      },
      {
        id: "settingdelegation",
        label: "Delegation"
      },
      {
        id: "settingmembers",
        label: "Members"
      },
      {
        id: "settingadvanced",
        label: "Advanced"
      }
    ]
  }
];

export const communitySettingsMenu = [
  {
    id: "enableReward",
    icon: rewardIcon,
    label: "Reward and Referral"
  },

  {
    id: "manageProjectCategories",
    icon: ManageCategoryIcon,
    label: "Manage Post Categories"
  },

  {
    id: "manageTheme",
    icon: ManageTheme,
    label: "Manage Theme"
  }
];

export const communityResourcesMenu = [
  {
    id: "allresources",
    icon: rewardIcon,
    label: "All Resources"
  },
  // {
  //   id: "enableReferral",
  //   icon: earnRefferal,
  //   label: "Enable Referral"
  // },
  {
    id: "training",
    icon: ManageCategoryIcon,
    label: "Training"
  },
  {
    id: "project",
    icon: ManageTheme,
    label: "Project"
  }
];

export const communityRewardsMenu = [
  {
    id: "earnReward",
    icon: "",
    label: "Earned Points"
  },
  // {
  //   id: "referralReward",
  //   icon: ReferralRewardIcon,
  //   label: "Referral Reward"
  // }
  {
    id: "spentPoints",
    icon: "",
    label: "Spent Points"
  }
];

export const redeemNFTRewards = [
  {
    id: "claimReward",
    icon: ClaimReward,
    label: "Redeem NFT Rewards"
  }
];

export const defaultCommunity = "default-platform-community";
export const defaultCommunityId = "64f7f941880c62e992c5c0ae";
export const vipCommunity = "vip-community";

export const categoryList = [
  { title: "De-fi" },
  { title: "NFT" },
  { title: "Metaverse" },
  { title: "Gaming" },
  { title: "Tech & Development" },
  { title: "Blockchain" },
  { title: "Education & Learning" },
  { title: "Inovation & Future Trends" }
];

export const CROWDSALE_CONTRACT_ADDRESS = "0xF6a5b70dae8a0aa0E5Bf2cE8ca2edc10Aa4db67B";
export const CROWDSALE_NETWORK_MAP = {
  "0x05": {
    CROWDSALE_CONTRACT_ADDRESS: "0xF6a5b70dae8a0aa0E5Bf2cE8ca2edc10Aa4db67B"
  },
  "0x013881": {
    CROWDSALE_CONTRACT_ADDRESS: "0xF6a5b70dae8a0aa0E5Bf2cE8ca2edc10Aa4db67B"
  },
  "0xaa36a7": {
    CROWDSALE_CONTRACT_ADDRESS: "0x75f75891C794b4CF09eA4A52E3660f967D4AE75f"
  }
};
const snapshotBase =
  ENVIRONMENT === "DEV"
    ? {
        hub: "https://wsngxq2zru5y4mp3j25sqhv6ru0xbdbm.lambda-url.ca-central-1.on.aws/api",
        sequencer: "https://dt25jz7rprx5xxzcui6sy4yro40vadwi.lambda-url.ca-central-1.on.aws",
        ui: "https://d1u0yqksooj0fy.cloudfront.net"
      }
    : {
        hub: "https://wsngxq2zru5y4mp3j25sqhv6ru0xbdbm.lambda-url.ca-central-1.on.aws/api",
        sequencer: "https://dt25jz7rprx5xxzcui6sy4yro40vadwi.lambda-url.ca-central-1.on.aws",
        ui: "https://d1u0yqksooj0fy.cloudfront.net"
      };

export const snapshotEndpoints = {
  rest: {
    get_spaces_by_wallet_address: (walletAddress) =>
      `${snapshotBase.hub}/spaces/wallet/${walletAddress}`,
    get_space_by_id: (spaceId) => `${snapshotBase.hub}/spaces/by/id/${spaceId}`
  },
  graph: {
    create_space: () => `${snapshotBase.sequencer}/`
  },
  ui: {
    space_link: (id) => `${snapshotBase.ui}/#/${id}`
  }
};

export const documentTypes = {
  image: [".png", ".jpg", ".jpeg"],
  document: ["pdf"],
  text: ["txt", "doc", "docx"]
};

export const COUNTRY_API_KEY =
  "0vkKSfToLD166lRRQAp17mr1qvqNHfxymgerOWYrCpKwmExBhJPDfmD_aLDpVF4C_Jk";
export const COUNTRY_API_KEY_EMAIL = "mschauhan15997@gmail.com";

export const supportedChains = [
  // {
  //   chainId: hexlify(5),
  //   chainIdInNumber: 5,
  //   rpcUrls: ["https://rpc.ankr.com/eth_goerli"],
  //   chainName: "Goerli",
  //   nativeCurrency: {
  //     name: "ETH",
  //     symbol: "ETH",
  //     decimals: 18
  //   },
  //   blockExplorerUrls: ["https://goerli.etherscan.io"]
  // },
  {
    chainId: hexlify(11155111),
    chainIdInNumber: 11155111,
    rpcUrls: ["https://rpc.ankr.com/eth_sepolia"],
    chainName: "Sepolia",
    nativeCurrency: {
      name: "ETH",
      symbol: "ETH",
      decimals: 18
    },
    blockExplorerUrls: ["https://sepolia.etherscan.io"]
  },
  {
    chainId: hexlify(80001),
    chainIdInNumber: 80001,
    rpcUrls: ["https://rpc.ankr.com/polygon_mumbai"],
    chainName: "Polygon Mumbai (Non functional)",
    nativeCurrency: {
      name: "MATIC",
      symbol: "MATIC",
      decimals: 18
    },
    blockExplorerUrls: ["https://mumbai.polygonscan.com"]
  }
];

export const exchangeOptions = {
  "0xaa36a7": {
    defaultCurrency: "0xD9Ab5A246Ee3D9D0E04332c25A44aA2923337ce0",
    allOptions: [
      {
        label: "USDT",
        value: "0xD9Ab5A246Ee3D9D0E04332c25A44aA2923337ce0"
      },
      {
        label: "USDC",
        value: "0x036B1d894F04c4a2944f69496D2F51e228d5d809"
      }
    ]
  },
  "0x013881": {
    defaultCurrency: "0x0FA8781a83E46826621b3BC094Ea2A0212e71B23",
    allOptions: [
      {
        label: "USDC",
        value: "0x0FA8781a83E46826621b3BC094Ea2A0212e71B23"
      }
    ]
  }
};

export const walletConnectMetadata = {
  name: "Societi",
  description:
    "The Societi community is all about and how this platform will change the way you think about profiting from blockchain and cryptocurrency.",
  url: window.origin,
  icons: [`${window.origin}/static/media/logoSymbol.c68ffe49503f48e93aecc23897420e21.svg`]
};
export const walletConnectProjectId = "01cd70f245b0eb7f9cedd372b067b5b7";
export const GOOGLE_CLIENT_ID =
  "925671008088-3jlv1mj35orto8k7oqqiqaj1tt0r7q2p.apps.googleusercontent.com";
