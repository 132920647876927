import { configureStore } from "@reduxjs/toolkit";
import settingReducer from "./setting/reducers";
import signInReducer from "./signIn/reducers";
import commonReducer from "./common/reducers";
import homeReducer from "./home/reducers";
import authReducer from "./Auth/reducer";
import communityReducer from "./Community/reducer";
import walletReducer from "./wallet/reducers";

export const store = configureStore({
  reducer: {
    setting: settingReducer,
    signIn: signInReducer,
    commonState: commonReducer,
    homeState: homeReducer,
    authReducer: authReducer,
    communityReducer: communityReducer,
    walletReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    })
});
