import { truncateAddressFromMiddle } from "../../utilities/SeprateMethods/WalletMethods";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { setSnackbar } from "../common/actions";
import { store } from "..";

const initialState = {
  signer: null,
  address: null,
  provider: null,
  walletProvider: null
};

export const connectWallet = createAsyncThunk("wallet/connectWallet", async () => {
  const wcModel = global.wcModel;
  const { walletAddress } = JSON.parse(localStorage.getItem("userData") || "{}");
  if (walletAddress) {
    const connectedAddressIfAny = wcModel.getAddress();
    if (connectedAddressIfAny) {
      if (connectedAddressIfAny.toLowerCase() !== walletAddress.toLowerCase()) {
        return store.dispatch(
          setSnackbar({
            open: true,
            variant: "error",
            message: `You are trying to connect using the wrong wallet address. Open your wallet and connect to ${truncateAddressFromMiddle(
              walletAddress,
              6
            )}.`
          })
        );
      }
    }
  }
  await wcModel.open();
});

export const walletSlice = createSlice({
  name: "wallet",
  initialState,
  reducers: {
    resetWalletData: () => {
      return initialState;
    },
    setWalletData: (_, action) => {
      return action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(connectWallet.fulfilled, (_, action) => {
      return action.payload;
    });
  }
});

export const numberOfZeros = (n) => {
  let c = 0;
  while (!~~n) {
    c++;
    n *= 10;
  }
  return c - 1;
};

export default walletSlice.reducer;
