//router
import IndexRouters from "./router/index";
//scss
import "./assets/scss/socialv.scss";
import "./utilities/FormUtils/style.scss";
// import "./assets/scss/customizer.scss";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { useEffect } from "react";
import LoadingOverlay from "react-loading-overlay";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { authenticate } from "./app/Auth";
import Snackbar from "./components/snackbar";
import { ENVIRONMENT, GOOGLE_CLIENT_ID } from "./Constants";
import { setSnackbar } from "./store/common/actions";
import { snackbarValue, spinnerValue } from "./store/common/selectors";
import { getCommunity } from "./store/Community/actions";

function App() {
  const dispatch = useDispatch();
  const spinner = useSelector(spinnerValue);
  const snackbar = useSelector(snackbarValue);
  const { community, isUnauthorized } = useSelector((state) => state.communityReducer);
  const { isLoggedIn } = useSelector((state) => state.authReducer);

  //const ENV = process.env.ENVIRONMENT || "PROD"; //PROD or DEV
  const ENV = ENVIRONMENT || "PROD"; //PROD or DEV

  useEffect(() => {
    const userData = localStorage.getItem("userData") || "";
    if (userData) {
      authenticate(JSON.parse(userData));
    }
    if (ENV === "PROD") {
      document.addEventListener("contextmenu", function (e) {
        e.preventDefault();
      });
      document.addEventListener("keydown", function (e) {
        if (
          e.key === 123 ||
          ctrlShiftKey(e, "I") ||
          ctrlShiftKey(e, "J") ||
          ctrlShiftKey(e, "C") ||
          (e.ctrlKey && e.key === "U".charCodeAt(0))
        )
          e.preventDefault();
      });
    }
    if (window.location.pathname.includes("community")) {
      dispatch(getCommunity(window.location.pathname.split("/")[2]));
    }
  }, [window.location.pathname.includes("community")]);

  useEffect(() => {
    if (community) {
      const newColorScheme = community?.themeColors;
      if (newColorScheme) {
        document.documentElement.style.setProperty("--text_color", newColorScheme.textColor);
        document.documentElement.style.setProperty("--primary_color", newColorScheme.primary);
        document.documentElement.style.setProperty("--card_color", newColorScheme.card);
        // document.documentElement.style.setProperty("--cta_background", newColorScheme.cta);

        document.documentElement.style.setProperty("--cta_color", newColorScheme.cta);
        document.documentElement.style.setProperty("--background_color", newColorScheme.background);

        document.documentElement.style.setProperty("--inactive_icon", newColorScheme.inactive);
        document.documentElement.style.setProperty("--icon_color", newColorScheme.inactive);
        document.documentElement.style.setProperty("--border_color", newColorScheme.stroke);

        document.documentElement.style.setProperty("--heading_color", newColorScheme.heading);
        document.documentElement.style.setProperty("--text_color", newColorScheme.description);
      }
    }
  }, [community]);

  const ctrlShiftKey = (e, keyCode) => {
    return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
  };

  return (
    <LoadingOverlay
      active={spinner}
      spinner={spinner}
      styles={{
        wrapper: {
          width: "100%",
          height: "100%",
          overflow: "unset"
        },
        overlay: (base) => ({
          ...base,
          background: "rgba(0,0,0,0.3)"
        })
      }}
    >
      <div className="App">
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
          <IndexRouters />
          <Snackbar
            snackbar={snackbar}
            handleClose={() => {
              dispatch(setSnackbar({ ...snackbar, open: false, message: "" }));
            }}
          />
          <ToastContainer autoClose={10000} icon={false} pauseOnFocusLoss={false} />
        </GoogleOAuthProvider>
        {/* </Elements> */}
      </div>
      {/* <ScrollButton /> */}
    </LoadingOverlay>
  );
}
export default App;
