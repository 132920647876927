import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "../src/assets/main.scss";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store";
import ScrollToTop from "./components/ScrollToTop";
import { createWeb3Modal, defaultConfig } from "@web3modal/ethers5";
import { httpRequest } from "./app/Auth";

import {
  UPDATE_USER_DATA,
  supportedChains,
  walletConnectMetadata,
  walletConnectProjectId
} from "./Constants";
import { setSnackbar, setSpinner } from "./store/common/actions";
import { providers } from "ethers";
import { resetWalletData, setWalletData } from "./store/wallet/actions";
import { truncateAddressFromMiddle } from "./utilities/SeprateMethods/WalletMethods";

global.wcModel = createWeb3Modal({
  ethersConfig: defaultConfig({ metadata: walletConnectMetadata }),
  chains: supportedChains.map(
    ({ blockExplorerUrls, chainIdInNumber, chainName, nativeCurrency, rpcUrls }) => ({
      chainId: chainIdInNumber,
      name: chainName,
      currency: nativeCurrency.symbol,
      explorerUrl: blockExplorerUrls[0],
      rpcUrl: rpcUrls[0]
    })
  ),
  projectId: walletConnectProjectId,
  enableAnalytics: true,
  // themeMode: "light",
  allowUnsupportedChain: false,
  // allWallets: "HIDE"
  featuredWalletIds: [
    "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
    "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa"
  ],
  themeVariables: {
    "--w3m-color-mix": "var(--primary_color)",
    "--w3m-color-mix-strength": 50,
    "--w3m-z-index": 10,
    "--w3m-accent": "var(--text_color)"
  }
});

global.wcModel.subscribeProvider(
  async ({ provider, providerType, address, error, chainId, isConnected }) => {
    console.log({ provider, providerType, address, error, chainId, isConnected });
    if (
      !address ||
      !isConnected
      // || chainId !== 5
    ) {
      global.wcModel.resetAccount();
      return store.dispatch(resetWalletData());
    }
    const userData = localStorage.getItem("userData") || "";
    if (!userData) {
      store.dispatch(
        setSnackbar({
          open: true,
          variant: "error",
          message: "Login before calling connectWallet()."
        })
      );
      return;
    }
    const { walletAddress } = JSON.parse(userData);
    if (!walletAddress) {
      await httpRequest({
        type: "post",
        endpoint: UPDATE_USER_DATA,
        instance: "instanceTwo",
        formData: {
          walletAddress: address
        },
        callBack: (data) => {
          store.dispatch(setSpinner(false));
          if (data && data.status) {
            store.dispatch(
              setSnackbar({
                open: true,
                variant: "success",
                message: `Account ${address} was linked to your account.`
              })
            );
          }
        },
        isPublic: false
      });
      const uData = JSON.parse(userData);
      uData.walletAddress = address;
      localStorage.setItem("userData", JSON.stringify(uData));
      const ethersProvider = new providers.Web3Provider(provider);
      const siner = ethersProvider.getSigner(walletAddress);
      await siner.provider._networkPromise;
      return store.dispatch(
        setWalletData({
          signer: siner,
          address,
          provider: ethersProvider,
          walletProvider: provider
        })
      );
    } else {
      if (address.toLowerCase() === walletAddress.toLowerCase()) {
        const ethersProvider = new providers.Web3Provider(provider);
        const siner = ethersProvider.getSigner(walletAddress);
        await siner.provider._networkPromise;
        return store.dispatch(
          setWalletData({
            signer: siner,
            address,
            provider: ethersProvider,
            walletProvider: provider
          })
        );
      } else {
        store.dispatch(resetWalletData());
        store.dispatch(
          setSnackbar({
            open: true,
            variant: "error",
            message: `You are trying to connect using the wrong wallet address. Open your wallet and connect to ${truncateAddressFromMiddle(
              walletAddress,
              6
            )}.`
          })
        );
        return;
      }
    }
  }
);

ReactDOM.render(
  <React.StrictMode>
    <React.Suspense fallback={<span></span>}>
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <Provider store={store}>
          <ScrollToTop>
            <App />
          </ScrollToTop>
        </Provider>
      </BrowserRouter>
    </React.Suspense>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
