import * as CommunityType from "./types";

const initialState = {
  community: null,
  communityReviewData: null,
  users: null,
  communities: null,
  isModalCreateTopic: false,
  isUnauthorized: false,
  topics: {},
  formFilterData: {
    offset: 0,
    limit: 4,
    sorting: "recent",
    type: ""
  },
  selectedTagList: [],
  levelPercentage: [],
  icoDetails: [],
  popularTags: [],
  investorPurchaseDetails: [],
  trendingTopics: null,
  rewards: {},
  rewardsPoints: {},
  referralList: {},
  allUsersList: null,
  categories: null,
  myFriends: null,
  resource: null,
  allResource: null,
  recommendedResource: null,
  stripeAccounts: [],
  selectedRewardMenu: null
};
const communityReducer = (state = initialState, { type, payload }) => {
  try {
    switch (type) {
      case CommunityType.GET_COMMUNITY:
        return {
          ...state,
          community: payload?.data || null,
          communityReviewData: payload?.reviewData || null,
          isUnauthorized: false
        };
      case CommunityType.GET_USERS:
        return {
          ...state,
          users: payload
        };
      case CommunityType.GET_COMMUNITIES:
        return {
          ...state,
          communities: payload
        };
      case CommunityType.SET_MODAL_CREATE_TOPIC:
        return {
          ...state,
          isModalCreateTopic: !state.isModalCreateTopic
        };
      case CommunityType.SET_UNAUTHORIZED:
        return {
          ...state,
          isUnauthorized: true
        };
      case CommunityType.SET_TAG_LIST:
        return {
          ...state,
          selectedTagList: payload
        };
      case CommunityType.GET_TOPICS:
        return {
          ...state,
          ...payload
        };
      case CommunityType.GET_LEVEL_PERCENTAGE:
        return {
          ...state,
          levelPercentage: payload
        };
      case CommunityType.GET_ICO_DETAILS:
        return {
          ...state,
          icoDetails: payload
        };
      case CommunityType.GET_POPULAR_TAGS:
        return {
          ...state,
          popularTags: payload
        };
      case CommunityType.GET_TRENDING_TOPICS:
        return {
          ...state,
          trendingTopics: payload
        };
      case CommunityType.GET_REWARD:
        return {
          ...state,
          rewards: payload
        };
      case CommunityType.GET_REWARD_POINTS:
        return {
          ...state,
          rewardsPoints: payload
        };
      case CommunityType.GET_REFERRAL_LIST:
        return {
          ...state,
          referralList: payload
        };
      case CommunityType.GET_INVESTOR_PURCHASE:
        return {
          ...state,
          investorPurchaseDetails: payload
        };
      case CommunityType.SET_USERS_LIST:
        return {
          ...state,
          allUsersList: payload
        };
      case CommunityType.SET_CATEGORIES_LIST:
        return {
          ...state,
          categories: payload
        };

      case CommunityType.SET_MY_FRIENDS:
        return {
          ...state,
          myFriends: payload
        };
      case CommunityType.RESET_ALL_STATES:
        return {
          ...initialState
        };
      case CommunityType.SET_RESOURCE:
        return {
          ...state,
          resource: payload
        };
      case CommunityType.SET_ALL_RESOURCE:
        return {
          ...state,
          allResource: payload
        };
      case CommunityType.RECOMMENDED_RESOURCE:
        return {
          ...state,
          recommendedResource: payload
        };
      case CommunityType.SET_STRIPE_ACCOUNTS:
        return {
          ...state,
          stripeAccounts: payload
        };
      case CommunityType.SET_SELECTED_REWARD_MENU:
        return {
          ...state,
          selectedRewardMenu: payload
        };
      case CommunityType.UPDATE_THEME_COLOR:
        return {
          ...state,
          community: {
            ...state.community,
            themeColors: {
              ...state.community.themeColors,
              ...payload
            }
          }
        };

      default:
        return state;
    }
  } catch (err) {
    console.log(err);
    return state;
  }
};

export default communityReducer;
