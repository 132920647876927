export const codes = {
  109: "Incorrect GoogleAuth secret key",
  110: "Unable to verify your email, please generate a new verification link",
  111: "Email verification link expired, please generate a new verification link",
  112: "Email Address not found. Please check your email or sign up for a new account.",
  113: "You have insufficient balance for this transaction",
  114: "User not available",
  115: "Current password entered is incorrect",
  116: "Unable to verify tfa token, please try again",
  117: "Please enter GoogleAuth token",
  118: "Link expired",
  119: "User not available",
  120: "Incorrect password",
  121: "Please verify your account from the link received in mail",
  122: "This account has been disabled",
  123: "Please approve the new IP from email",
  124: "Email is either verified or has not been registered",
  125: "Email Address or username already register with us",
  127: "Email has already been sent for password reset. Please check your email",
  128: "Social Media Activity Already Exist",
  129: "OTP Expired",
  126: "Referral Code is Invalid!",
  130: "User is registered using google",
  131: "please verify tfa",
  132: "Account isn't registered using google. Please use email and password to log in.",
  133: "username alrady registered",
  134: "Account already connected with this wallet address",
  135: "this wallet address is already assigned to another account",
  136: "User data updated successfully",
  137: "image upload successfully completed",
  138: "image resize failed",
  139: "something went wrong with s3",
  141: "image watermark failed",
  142: "TFA disabled successfully",
  143: "TFA Auth Enabled",
  400: "Invalid input",
  401: "Unauthorized action",
  403: "Session, expired, please login again",
  500: "Some error occurred, please try again later",
  600: "Please check your internet connection status",

  204: "Your password has been changed successfully. You can now login with your new password",
  205: "Your password has been changed successfully!",
  206: "Order placed successfully!",
  207: "Order cancelled successfully!",
  208: "Your Email ID has been registered successfully!",
  209: "Email has been verified. Please login to continue",
  210: "IP whitelisted enabled",
  211: "Your message has been sent successfully. We will get back to soon.",
  212: "Transaction locked successfully",
  214: "Request Submitted",
  215: "IP whitelisted disabled",
  216: "activity deleted successfully",
  217: "Required param missing",
  218: "not allowed to send request to self",
  219: "Request does not exist",
  220: "Invalid request",
  221: "You are already friend with this user",
  222: "You already sent friend request to this user",
  223: "You already received request from this user",
  224: "Request cancelled successfully",
  225: "Unfriend Successfully",
  226: "Friend request sent successfully",
  227: "Error retrieving data",
  228: "Already accepted as a friend",
  229: "Accepted as a friend",
  230: "User Id InValid",
  232: "Following User Id InValid",
  233: "you already followed this user",
  234: "Some error occurred while adding the data",
  235: "Email is not verified by google",
  236: "Action updated successfully",
  237: "",
  238: "",
  239: "",
  240: "",
  241: "",
  242: "",
  243: "",
  244: "",
  245: "",
  246: "",
  247: "",
  248: "",
  249: "",
  250: "",
  251: "Community updated for review successfully",
  252: "Community review updated successfully",
  253: "You are not allowed to modify this data or the record does not exist",
  254: "Community sent for review successfully",
  255: "Community name already exist!",
  256: "Project has been successfully submitted and is now under review.",
  257: "Community deleted successfully",
  258: "Cannot delete community",
  259: "You are not allowed to modify this data",
  260: "Community Rejected",
  261: "Project published successfully",
  262: "Cannot update community",
  263: "Module Access Update",
  264: "Published successfully",
  265: "Unpublished successfully",
  266: "Default Project submitted successfully",
  267: "Featured project assigned successfully",
  268: "Featured project unassigned successfully",
  269: "Cannot update community action status",
  270: "Invidation declined successfully",
  271: "Invite Id is required",
  272: "Payment already done for this action",
  273: "No account is associated with this",
  274: "You are not allowed to join this default community directly.",
  275: "You are already a member of this community as admin",
  276: "Already Member",
  277: "you joined successfully",
  278: "You are not allowed to send join request this community directly",
  279: "You are already a member of this community as admin",
  280: "Request sent successfully",
  281: "Project Manager already removed / declined your request.",
  282: "Project Manager already accepted your request",
  283: "You have already sent join request",
  284: "You can't leave from this default community directly",
  285: "You can't leave from this project because you are a creator",
  286: "Member not Founded",
  287: "You leaved successfully",
  288: "Owner / Admin only can add members",
  289: "You are already a member as community admin",
  290: "members added successfully",
  291: "Platform Admin is not allowed to join any community",
  292: "Password is incorrect",
  293: "Manage access granted successfully",
  294: "Community data not available",
  295: "'Owner / Admin can only remove manage access",
  296: "This user does not have admin access",
  297: "This user does not have content manager access",
  298: "Manage access removed successfully",
  299: "Community data not available",
  301: "Topic already exist!",
  302: "This post is rejected, content meets violation over rules!",
  303: "Topic created and sent approval for admin",
  304: "Topic created and published successfully",
  305: "Some error occurred while adding the data",
  306: "Selected Category Invalid!",
  307: "You are not allowed to create topic.",
  308: "Topic published successfully",
  309: "Topic unpublished successfully.",
  310: "Cannot update topic",
  311: "Error updating topic",
  312: "topic title already exist!",
  313: "This post is rejected, content meets violation over rules",
  314: "Topic updated and sent approval for admin successfully",
  315: "Topic updated successfully",
  316: "Some error occurred while adding the data.",
  317: "Topic title invalid",
  318: "topic deleted successfully",
  319: "topic id invalid",
  320: "Cannot delete topic",
  321: "Error delete topic ",
  322: "topic or category id invalid",
  323: "successfully locked topic",
  324: "Cannot find topic data",
  325: "Error retrieving topic ",
  326: "successfully locked topic",
  327: "Cannot find topic data",
  329: "successfully pinned topic",
  330: "successfully unpinned topic",
  331: "topic forked successfully",
  332: "voted successfully",
  333: "You are not allowed to like this data",
  334: "subscribed successfully",
  335: "unsubscribed successfully",
  336: "Error retrieving Subscribtion data",
  337: "You are not allowed to subscribe this data",
  338: "image uploaded successfully",
  339: "no image selected",
  340: "Post id invalid!",
  341: "Topic is locked",
  342: "reply added successfully",
  343: "Content should not be empty",
  344: "You are not allowed to update topic data",
  345: "Community Id is invalid",
  346: "reply updated successfully",
  347: "Cannot update reply",
  348: "Error updating reply",
  349: "reply deleted successfully",
  350: "Cannot delete reply",
  351: "already you flagged this item",
  352: "flagged successfully",
  353: "Category name already exist!",
  354: "Resource added successfully",
  355: "You are not allowed to add this data",
  356: "Already enrolled in the course",
  357: "course enrolled",
  358: "You are not allowed subscribe to this course",
  359: "you are not enrolled to this course",
  360: "course doesn't exist",
  361: "Progress updated successfully",
  362: "Published successfully",
  363: "Unpublished successfully",
  364: "Cannot update course",
  365: "Error updating course",
  366: "You are not allowed to do this course",
  367: "Resource updated successfully",
  368: "Cannot update course",
  369: "Error updating course",
  370: "Resource deleted successfully",
  371: "Cannot delete course",
  372: "Error course delete",
  373: "resource already published",
  374: "module(s) added/updated successfully",
  375: "title already exist!",
  376: "Course Content updated successfully",
  377: "Cannot update content",
  378: "Error updating content",
  379: "Course content deleted successfully",
  380: "Cannot delete course content",
  381: "updated successfully",
  382: "Reward points only positive number allowed",
  383: "Activity Referral points only positive number allowed",
  384: "Invalid type parameter",
  385: "Settings updated successfully",
  386: "Cannot update community reward and referral point",
  387: "Cannot update community activity referral point",
  388: " Cannot update community add referral levels point ",
  389: "Category added successfully",
  390: "Category name already exist!",
  391: "Category updated successfully",
  392: "Cannot update category ",
  393: "Error updating category",
  394: "You are not allowed to delete default category",
  395: "Cannot delete category",
  396: "Category deleted successfully",
  397: "Error deleting category",
  398: "Category deleted successfully",
  399: "Cant deleted this category",
  402: "Category name already exists:",
  405: "Invalid status provided for category",
  406: "Category added successfully",
  410: "Settings updated successfully",
  411: "Error updating community theme colors",
  412: "Owner can only give manage access",
  413: "Still Member is not joined this community",
  414: "Already user has admin access",
  415: "Already user has content manager access",
  416: "Owner / Admin can only remove members",
  417: "member removed successfully",
  418: "Notification Settings Updated Successfully",
  419: "Invalid senderId array",
  420: "Post shared successfully",
  421: "No matching record found",
  422: "Parameter missing",
  423: "Post deleted successfully",
  424: "No matching reply found",
  425: "Reply deleted successfully",
  426: "Saved/Updated successfully",
  427: "'Ico details added successfully",
  428: "Invitation sent successfully",
  429: "Community Not found",
  430: "No AI credit information found for the user",
  431: "Insufficient credits. Please purchase more credits",
  432: "Transaction processed",
  433: "invalid transaction",
  434: "Ico updated successfully",
  435: "Cannot update Ico",
  436: "transaction hash been already processed!",
  437: "page title already exist!",
  438: "page updated successfully",
  439: "Cannot update page",
  440: "Error updating page",
  441: "Template name already exist!",
  442: "Template updated successfully",
  443: "Cannot update Template",
  444: "Error updating Template",
  445: "Category published successfully.",
  446: "Category unpublished successfully.",
  448: "Error updating category",
  449: "Cannot update category",
  450: "Api key updated successfully",
  451: "This API KEY alredy registred with user",
  452: "Currently this functionality is not supported",
  453: '"apiKey" is required',
  454: '"paymentMode" is required',
  455: "Payment Already Done",
  456: "You already have a pending payment for this community/resource. Please wait until the current payment is processed.",
  300: "Approve your IP first",
  447: "Insufficient Balance"
};
