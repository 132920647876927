import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state.ts";
import _ from "lodash";

export const commonSlice = createSlice({
  name: "commonState",
  initialState,
  reducers: {
    setSpinner: (state, action) => {
      state.spinner = action.payload;
    },
    setLoader: (state, action) => {
      state.loader = action.payload;
    },
    setSnackbar: (state, action) => {
      state.snackbar = action.payload;
    },
    setProfileActiveTab: (state, action) => {
      state.profileActiveTab = action.payload;
    },
    setCommunityFilterQuery: (state, action) => {
      state.communityFilterQuery = action.payload;
    },
    setResourceFilterQuery: (state, action) => {
      state.resourceFilterQuery = action.payload;
    }
  }
});

export default commonSlice.reducer;
