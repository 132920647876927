import React from "react";
//router
import { Route, Switch, useHistory } from "react-router-dom";
import {
  ADMIN,
  ADMIN_URLS,
  APPROVE_IP,
  CONFIRM_MAIL,
  EMAILID,
  ERROR404,
  ERROR500,
  HOME,
  JOINCOMMUNITY,
  LOCK_SCREEN,
  PAYMENT_STATUS,
  RECOVER_PW,
  SIGNUP,
  TERMSOFSERVICES,
  VERIFY_ACCOUNT,
  WELCOME
} from "../Constants";
import { checkAdmin } from "../utils";

//layoutpages
const Home = React.lazy(() => import("../layouts/dashboard/home"));
const Welcome = React.lazy(() => import("../views/welcome/CommunityHomePage"));

const SignIn = React.lazy(() => import("../components/Login/sign-in"));
const EmailId = React.lazy(() => import("../views/dashboard/auth/email-id"));
const JoinCommunity = React.lazy(() => import("../views/dashboard/auth/Join-community"));

const ConfirmMail = React.lazy(() => import("../views/dashboard/auth/confirm-mail"));
const LockScreen = React.lazy(() => import("../views/dashboard/auth/lock-screen"));
const Recoverpw = React.lazy(() => import("../views/dashboard/auth/recoverpw"));
const SignUp = React.lazy(() => import("../views/dashboard/auth/sign-up"));
const Error404 = React.lazy(() => import("../views/dashboard/errors/error404"));
const Error500 = React.lazy(() => import("../views/dashboard/errors/error500"));
const AccountVerify = React.lazy(() => import("../views/dashboard/auth/account-verify"));
const ApproveIP = React.lazy(() => import("../views/dashboard/auth/approve-ip"));
const termsofServices = React.lazy(() => import("../views/general/termsofServices"));

const IndexRouters = () => {
  const history = useHistory();
  let isAdmin = checkAdmin();
  if (!isAdmin && ADMIN_URLS.some((url) => url === window.location.pathname)) {
    localStorage.setItem("userData", "");
    history.push(EMAILID);
  }
  return (
    <>
      <Switch>
        <Route exact path={"/"} component={EmailId} />
        <Route path={JOINCOMMUNITY} component={JoinCommunity} />
        <Route path={VERIFY_ACCOUNT} component={AccountVerify} />
        {/* auth */}
        <Route path={CONFIRM_MAIL} component={ConfirmMail} />
        <Route path={LOCK_SCREEN} component={LockScreen} />
        <Route path={RECOVER_PW} component={Recoverpw} />
        <Route path={APPROVE_IP} component={ApproveIP} />

        {/* error */}
        <Route path={ERROR404} component={Error404} />
        <Route path={ERROR500} component={Error500} />
        {/* other */}
        <Route path={"/"} component={Home} />
        {/* <Route path={ADMIN} component={Home} /> */}
        {/* <Route path={WELCOME} component={Welcome} /> */}
      </Switch>
    </>
  );
};

export default IndexRouters;
